<template>

  <div id="e-fes-go-digital">

    <div class="go-digital-wrapper">
      <div class="circles" v-html="svgIcons.circles"/>
      <div class="container h-100 position-relative mb-5">
        <div class="row align-items-center pt-5">

          <div class="col-12">

            <h2 class="text-blue title">Go Digital</h2>
            <h5 class="text-white mt-4">Τα συστήματά μας</h5>

          </div>
        </div>
      </div>
      <img src="@/assets/go-digital/efes/gd-e-fes-granazi1.png" class="gear-left animate__animated animate__fadeInLeft"/>
      <img src="@/assets/go-digital/efes/gd-e-fes-granazi2.png" class="gear-right animate__animated animate__fadeInRight"/>

      <div class="tv-box">
        <video-screen-no-speakers video-url="https://edlservices.s3.eu-north-1.amazonaws.com/efes.mp4"></video-screen-no-speakers>
      </div>

      <div class=" central-box">

        <img src="@/assets/go-digital/efes/gd-e-fes-x.png" class="x animate__animated animate__fadeInLeft"/>
        <img src="@/assets/go-digital/efes/gd-e-fes-tick.png" class="tick animate__animated animate__fadeInBottomLeft"/>
        <img src="@/assets/go-digital/efes/gd-e-fes-mobile-man.png" class="phone"/>
        <div class="central-box-content">

          <h3 class="inner-content-title text-literata text-white mb-5">
            e-Fes
          </h3>
          <p class="text-white">
            Το e-FES, είναι μια web εγκριτική πλατφόρμα η οποία έχει δημιουργηθεί για την εξυπηρέτηση των χρηστών του
            Ομίλου προσφέροντας φιλικό περιβάλλον περιήγησης, δυνατότητες εύκολης χρήσης από κινητές συσκευές (τηλέφωνα και tablets) καθώς και εύκολο Login.
          </p>
          <div class="mt-5">
            <b-button variant="red" class="cta-button" @click="redirectTo('https://e-fes.mohapps.gr/')">Go to E-Fes</b-button>
          </div>

        </div>


      </div>

      <div class="row next-section-wrapper ">
        <div class="col-12 text-center">

          <prev-section-btn text="Go Digital" to-url="go-digital"></prev-section-btn>
        </div>
      </div>
    </div>



  </div>

</template>

<style lang="scss">
#e-fes-go-digital{
  .text-blue{
    color:#002d5d;
  }
  .next-section-wrapper{
    padding-top:5rem;
    padding-bottom:10rem;
  }

  overflow-x:hidden;
  position:relative;
  background: linear-gradient(0deg, #A2D0FF 0%, #6E98FF 66%, #5880FF 100%);

  .go-digital-wrapper{
    position:relative;
    max-width:1440px;
    margin:0 auto;
    .gear-left{
      position:absolute;
      top:25%;
      left: 0;
      width:120px;
    }
    .gear-right{
      position:absolute;
      top:40%;
      right: 0;
      width:70px;
    }
  }

  .central-box{
    background-color: #192C5520;
    position:relative;
    z-index:1;
    padding: 7rem 2rem;
    width:1100px;
    margin:0 auto;

    .x{
      position:absolute;
      width:60px;
      top:-30px;
      left:-35px;

    }
    .tick{
      position:absolute;
      width:120px;
      top:60px;
      left:-25px;
    }
    .phone{
      position:absolute;
      width:175px;
      bottom:-20px;
      right:-5%;
    }


    p{
      max-width:60%;
      margin:0 auto 1.5rem auto;
      text-align:justify;
      font-size:0.9rem;
    }
  }


  .tv-box{
    margin-bottom:7.5rem;
    .tv{
      width:950px;
    }
  }
}
</style>

<script>
import svgIcons from "../../components/svgIcons"
import PrevSectionBtn from "../../components/PrevSectionBtn";
import VideoScreenNoSpeakers from "../../components/VideoScreenNoSpeakers";
export default{

  scrollToTop: true,
  components:{VideoScreenNoSpeakers, PrevSectionBtn},
  data(){
    return{
      svgIcons

    }
  },
  mounted(){
    this.toggleFloatAnimations();
  }
}
</script>