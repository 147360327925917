<template>
  <div id="office-tour">


    <div class="part-1">
      <div class="container h-100">
        <div class="row align-items-center pt-5">

          <div class="col-12">

            <h2 class="text-blue">Office Tour</h2>

            <h5 class="text-white mt-4">Walk with me...</h5>


            <div class="content mt-5">
              <h3 class="text-white text-zonapro mb-4">
                <img src="@/assets/office-tour/location-icon.svg" class="location-item"/> Motoroil Group Headquarters
              <span class="text-zonapro" style="font-weight:300;font-size:1.2rem;">Μέγαρο ΚΟΝΚΑΤ</span></h3>

              <p class="text-white text-center text-zonapro">
                <span class="text-zonapro" style="font-weight:300;font-size:1rem;">
                  Στο κτήριο του Αμαρουσίου (Κονκατ) στεγάζονται τα κεντρικά γραφεία του Ομίλου Motor Oil<br>
                  Διεύθυνση:  <strong>Hρώδου Αττικού 12Α, 15124</strong><br>
                  Τηλέφωνο επικοινωνίας: <strong>210 809 4000</strong><br>
                  Τηλέφωνο υποδοχής: <strong>210 809 4150</strong>

                </span>
              </p>
              <img src="@/assets/office-tour/Megaro-Konkat.jpg" class="megaro-konkat cursor-pointer" @click="openLightbox([require('@/assets/office-tour/Megaro-Konkat.jpg')])" loading="lazy"/>


              <div class="inner-content text-left">
                <img src="@/assets/office-tour/entrance-card.png" class="entrance-card"/>

                <img src="@/assets/office-tour/reception2.png" class="reception-2"/>
                <img src="@/assets/images/card.png" class="small-card animate__fadeInLeft animate__animated"/>
                <img src="@/assets/office-tour/presentation-office.png" class="presentation-office"/>
                <div class="inner-content-shadow">
                  <h3 class="inner-content-title text-zonapro mb-4">
                    Υποδοχή
                  </h3>
                  <div class="content " style="max-width:80%">
                    <p>
                      Στην είσοδο των εγκαταστάσεων μας καθημερινά από τις 8:00-17:00 λειτουργεί ρεσεψιόν.
                    </p>
                    <p>Εκεί μπορείτε να καθοδηγηθείτε σχετικά με:</p><ul><li>την παραλαβή δεμάτων</li><li>θέματα ασφάλειας</li><li>την απώλεια της κάρτας εισόδου</li><li>τους χώρους των γραφείων</li><li>την υποδοχή των επισκεπτών</li></ul>
                  </div>

                  <h3 class="inner-content-title text-zonapro mt-5 mb-4">
                    Αίθουσα Συνεδριάσεων
                  </h3>
                  <div class="content " style="max-width:80%">
                   <p>
                     Σε περίπτωση που θέλετε να δεσμεύσετε μια αίθουσα συνεδριάσεων στα κεντρικά γραφεία,
                     θα πρέπει να καλέσετε την κα Μπακαλοπούλου Χριστίνα η οποία θα σας ενημερώσει εάν η αίθουσα είναι διαθέσιμη και θα την δεσμεύσει για εσάς.
                     Παρακάτω, μπορείτε να βρείτε τα στοιχεία επικοινωνίας της

                   </p>
                    <p>
                      Επικοινωνία: <strong>Μπακαλοπούλου Χριστίνα</strong><br>
                      Τηλ: <strong>210 8093772</strong><br>
                      Email: <strong>xmpakalopoulou@konkat.gr</strong>
                    </p>
                  </div>

<!--                  <div class="section-item">-->
<!--                    <h3 class="inner-content-section-title">-->
<!--                      Nrg-->
<!--                    </h3>-->
<!--                    <p>-->
<!--                      Επικοινωνία: <strong>Μαυριδάκη Αθηνά</strong><br>-->
<!--                      Email: <strong>amavridaki@konkat.gr</strong>-->
<!--                    </p>-->
<!--                  </div>-->


                </div>


              </div>

              <div class="inner-content text-left mt-5" >

                <img src="@/assets/images/doctor1.png" class="doctor"/>
<!--                <img src="@/assets/office-tour/plant.png" class="plant"/>-->
                <img src="@/assets/office-tour/pills-office.png" class="pills"/>
                <img src="@/assets/office-tour/doctors-degree.png" class="degree animate__animated animate__fadeInBottomRight"/>
                <img src="@/assets/office-tour/plant.png" class="plant"/>
                <img class="medicine animate__animated animate__fadeInBottomLeft" src="@/assets/images/medicine.png"/>
                <div class="inner-content-shadow" style="padding-top:3em;">
                  <h3 class="inner-content-title text-zonapro mb-3">
                    Ιατρείο
                  </h3>
                  <p>
                    Στα κεντρικά γραφεία του Ομίλου μας υπάρχει μόνιμα ιατρικό προσωπικό το οποίο μπορεί να σας εξυπηρετήσει καθημερινά από τις <strong>
                    8:00 έως τις 16:00.</strong>
                  </p>
                  <p>
                    Η ιατρική ομάδα αποτελείται από τον Γενικό ιατρό, Καρδιολόγο κι εξειδικευμένους διασώστες οι οποίοι είναι σε θέση να καλύψουν όλες τις προνοσοκομειακές σας ανάγκες
                    (Καρδιογράφημα, Ηolter Monitor, Aπινιδωτές,  Γενικά φάρμακα, Γενική διάγνωση) χρησιμοποιώντας σύγχρονο εξοπλισμό καθώς και τη διακομιδή σας σε Νοσοκομείο.
                  </p>

                  <p>
                    Παρακάτω θα βρείτε το τηλέφωνο επικοινωνίας των ιατρείων στα κεντρικά γραφεία και στο Διυλιστήριο:
                  </p>
                  <div class="section-item mt-5">
                    <h3 class="inner-content-section-title ">
                      Κεντρικά γραφεία
                    </h3>
                    <p>
                      Τηλ: <strong>210 8094101</strong>
                    </p>
                  </div>
                  <div class="section-item pb-4">
                    <h3 class="inner-content-section-title ">
                      Διυλιστήριο
                    </h3>
                    <p>
                      Τηλ: <strong>27410 41800 εσωτερικό 2247</strong>
                    </p>
                  </div>

                  <h3 class="inner-content-title text-zonapro mb-3 mt-4">
                    Φαρμακείο
                  </h3>
                  <p>
                    Μπορείτε γρήγορα κι εύκολα  να κάνετε την παραγγελία σας μέσω του Τeams (MOH Pharmacy) είτε στέλνοντας email, αλλά και να ενημερώνεστε για ειδικές προσφορές σε μεγάλη ποικιλία προϊόντων.
                  </p>
                  <p>
                    Υπάρχει δυνατότητα παράδοσης της παραγγελίας σας στα κεντρικά μας γραφεία καθώς και πληρωμή κατά την παραλαβή με POS.
                  </p>

                  <p>
                    Email: <strong>pharmacy@ceg.gr</strong><br>
                    Τηλ: <strong>210 8068663</strong>

                  </p>

                </div>


              </div>

              <div class="inner-content text-left mt-5 mb-5 " >
                <img src="@/assets/office-tour/spagetti.png" class="spaggeti animate__animated animate__fadeInRight"/>
                <img src="@/assets/office-tour/spoon.png" class="spoon animate__animated animate__fadeInTopRight"/>
                <img src="@/assets/office-tour/coffee.png" class="coffee animate__animated animate__fadeInBottomRight"/>
                <img src="@/assets/office-tour/menu.png" class="menu animate__animated animate__fadeInLeft"/>
                <img src="@/assets/images/restaurant.png" class="restaurant"/>

                <div class="inner-content-shadow" style="padding-top:3em;padding-bottom:5em;">
                  <h3 class="inner-content-title text-zonapro mb-3">
                    Εστιατόριο
                  </h3>
                  <p>
                    Στα κεντρικά γραφεία μας έχετε τη δυνατότητα να απολαύσετε φρεσκομαγειρεμένα γεύματα υψηλής διατροφικής αξίας,
                    κρύα κουζίνα καθώς κι εξαιρετικής  ποιότητας καφέ κι  ροφήματα από το Ι Love Cafe.
                  </p>
                  <p>
                    Παρακάτω μπορείτε να συνδεθείτε στο link ώστε να βρείτε το καθημερινό menu του εστιατορίου.

                  </p>

                  <b-button variant="red" class="cta-button" @click="redirectTo('https://motoroil.sharepoint.com/sites/el-MotorOil/SitePages/restaurants-and-menu.aspx')">Εστιατόρια & Μενού</b-button>
                </div>


              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <div class="part-2">
      <div class="container h-100">
        <div class="row align-items-center pt-5">

          <div class="col-12">

            <div class="content mt-5">

              <div class="row mb-4">
                <div v-for="building of buildings" :key="building.name" class="col-12 col-md-4 my-3 building">

                  <div class="position-relative">
                    <img :src="building.image" class="cursor-pointer" @click="openLightbox([building.image])" loading="lazy"/>

                    <div class="position-absolute enlarge-image-box cursor-pointer" style=""  @click="openLightbox([building.image])"  >
                      <img src="@/assets/enlarge-image.svg">
                    </div>
                  </div>

                  <div class="text-left mt-4">
                   <h3 class="text-white text-zonapro mb-4">
                     <img src="@/assets/office-tour/location-icon.svg" class="location-item" loading="lazy"/> {{building.name}}</h3>

                    <div class="content text-white" v-html="building.text"></div>

                  </div>

                </div>

                <div class="col-12 mt-3 pt-3" style="border-top:1px solid #fff">
                  <div class="content text-white">
                    <p>
                      Επίσης ο όμιλος έχει γραφεία και εγκαταστάσεις στην Κρήτη, Ιωάννινα, Αλεξανδρούπολη και Θεσσαλονίκη καθώς και στο εξωτερικό στην Κύπρο,
                      Κροατία και στη Σερβία.
                    </p>


                  </div>
                </div>
              </div>

              <div class="row next-section-wrapper">
                <img src="@/assets/office-tour/cloud1.png" class="cloud1 animate__animated animate__fadeInUp"/>
                <img src="@/assets/office-tour/cloud2.png" class="cloud2 animate__animated animate__fadeInUp"/>
                <img src="@/assets/office-tour/diilistirio.png" class="diilistirio"/>
                <img src="@/assets/office-tour/konkat.png" class="konkat"/>
                <div class="col-12 col-md-6 text-right">

                  <prev-section-btn text="Όμιλος Motoroil" to-url="motoroil-group"></prev-section-btn>
                </div>
                <div class="col-12 col-md-6 text-left">

                  <div class="next-section">

                    <next-section-btn text="My Tools" to-url="my-tools"></next-section-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#office-tour{

  .megaro-konkat{
    border:10px solid #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .content{
    p{
      text-align:justify;
      font-size:.9rem!important;
    }
  }
  p{
    strong{
      font-family: "ZonaPro", sans-serif;
    }
  }

  .inner-content-shadow{
    margin-left:3.6rem;
    background-color:#192C5520;
  }

  .entrance-card{
    position:absolute;
    right:0%;
    width:250px;
  }
  .reception-2{
    position:absolute;
    top:24%;
    left: -3.5%;
    width:300px;
  }
  .small-card{
    position:absolute;
    top:15%;
    left:-7%;
    width:60px;
    //transform:rotate(180deg);
  }

  .presentation-office{
    position:absolute;
    top:70%;
    right: 1.5%;
    width:300px;
  }

  .doctor{
    position:absolute;
    bottom:0;
    width:180px;
    right:7%;
  }
  .plant{
    position:absolute;
    bottom:0;
    width: 70px;
    right:-2%;
  }
  .degree{
    position:absolute;
    bottom: 260px;
    width: 70px;
    right:0%;
  }

  .pills{
    position:absolute;
    top: 50%;
    width: 150px;
    left:-2%;
  }

  .medicine{
    position:absolute;
    top: 10%;
    left: 3%;
    width:80px;
  }

  .spaggeti{
    position:absolute;
    bottom: 30%;
    right: 0%;
    width:100px;
  }
  .spoon{
    position:absolute;
    bottom: 50%;
    right: 10%;
    width:60px;
  }
  .coffee{
    position:absolute;
    bottom: 10%;
    right: 17%;
    width:100px;
  }
  .menu{

    position:absolute;
    top:10%;
    width:150px;
    left:0;

  }
  .restaurant{
    position:absolute;
    bottom:-10%;
    left:-15%;
    width:400px;
  }
  .text-blue{
    color:#192C55
  }
  .part-1{
    background: linear-gradient(174.3deg, #5CCAEC 0%, #78C9E2 25%, #ADC8CE 75%, #C2C7C6 100%);
    padding-bottom:3rem;
  }

  .part-2{
    background:linear-gradient(180deg, rgba(48, 132, 181, 0.75) 0%, rgba(79, 181, 220, 0.75) 66%, rgba(92, 202, 236, 0.75) 100%);
  }
  .location-item{
    margin-top:-10px;
    width:30px;
  }
  .building{
    > img{
      border:3px solid #fff;
    }

    p{
      text-align:left;
    }
  }

  .next-section-wrapper{
    position:relative;
    padding: 5rem 0 15rem;


  }

  .cloud1{
    position: absolute;
    left:0;
    top:10%;
    width:200px;

  }
  .cloud2{
    position: absolute;
    right:0;
    top:10%;
    width:200px;
  }
  .diilistirio{
    position: absolute;
    bottom:0;
    width:350px;
    left:6%;
  }
  .konkat {
    position: absolute;
  bottom:0;
    width:225px;
    right:10%;
  }
}

</style>


<script>
import NextSectionBtn from "../components/NextSectionBtn";
import PrevSectionBtn from "../components/PrevSectionBtn";
export default{

  components:{NextSectionBtn, PrevSectionBtn},

  mounted(){
    this.toggleFloatAnimations();
  },

  data(){
    return{

      buildings: [
        {
          name:'Κόρινθος',
          image: require('../assets/office-tour/Ktirio-Korinthos.jpg'),
          text: '<p>Στην Κόρινθο βρίσκεται το διυλυστήριο της εταιρείας Motor Oil.</p>' +
              '<p>Διεύθυνση: <strong>71ο χλμ Παλαιάς Εθνικής Οδού Αθηνών - Κορίνθου, 20003</strong><br>' +
              'Τηλέφωνο επικοινωνίας: <strong>274 1041 800</strong></p>'
        },
        {
          name:'nrg',
          image: require('../assets/office-tour/Ktirio-Nrg.jpg'),
          text: '<p>Στο κτήριο στεγάζονται όλα τα γραφεία της εταιρείας nrg.</p>' +
              '<p>Διεύθυνση: <strong>Λεωφ. Κηφισίας 168 & Σοφοκλέους, 15126</strong><br>' +
              'Τηλέφωνο επικοινωνίας: <strong>210 9606091</strong></p>'
        },
        {
          name:'MORE',
          image: require('../assets/office-tour/Ktirio-More-2.jpg'),
          text: '<p>Στο κτήριο στεγάζονται όλα τα γραφεία της εταιρείας MORE.</p>' +
              '<p>Διεύθυνση: <strong>Πάρνωνος 3, 14125 Μαρούσι</strong><br>' +
              'Τηλέφωνο επικοινωνίας: <strong>210 8094032</strong></p>'
        },
        {
          name:'Ασπρόπυργος',
          image: require('../assets/office-tour/Ktirio-Aspropirgos.jpg'),
          text: '<p>Στον Ασπρόπυργο βρίσκεται το διυλυστήριο της εταιρείας LPC (λιπαντικά).</p>' +
              '<p>Διεύθυνση: <strong>Μεγαρίδος 12, 19300</strong><br>' +
              'Τηλέφωνο επικοινωνίας: <strong>210 809 4000</strong></p>' },
        {
          name:'Περισσός',
          image: require('../assets/office-tour/Ktirio-Perissos.jpg'),
          text: '<p>Στα γραφεία του Περισσού στεγάζονται διοικητικές διευθύνσεις των εταιρειών Coral, Avin, Coral Gas & MOH (λογιστήριο, προμήθειες, Information Security κα).</p>' +
              '<p>Διεύθυνση: <strong>Γεωργίου Αβέρωφ 26-28, 14232</strong><br>' +
              'Τηλέφωνο επικοινωνίας: <strong>210 809 4000</strong></p>'
        },
        {
          name:'Πέραμα',
          image: require('../assets/office-tour/Ktirio-Perama.jpg'),
          text: '<p>Στα γραφεία του Περάματος στεγάζονται οι τεχνικές εγκαταστάσεις των εταιρειών Coral και Avin.</p>' +
              '<p>Διεύθυνση: <strong>Λεωφ. Δημοκρατίας 192, 18863</strong><br>' +
              'Τηλέφωνο επικοινωνίας: <strong>210 809 4000</strong></p>' },

      ]
    }
  }

}
</script>
