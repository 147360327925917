import Vue from "vue"
import VueRouter from "vue-router";
import Home from "./views/Home";
import MotoroilGroup from "./views/MotoroilGroup";
import OfficeTour from "./views/OfficeTour";
import MyTools from "./views/MyTools";
import GoDigital from "./views/GoDigital/GoDigital";
import EFes from "./views/GoDigital/EFes";
import Fiori from "./views/GoDigital/Fiori";
import Sharepoint from "./views/GoDigital/Sharepoint";
import HS from "./views/HS";
import BasicPolicies from "./views/BasicPolicies";
import myHR from "./views/MyHR";
import theEnd from "./views/TheEnd";
Vue.use(VueRouter);

const routes = [
    {path: '/',name: 'home', component: Home, meta: { transition: 'slide-right' },},
    {path: '/motoroil-group',name: 'motoroil-group', component: MotoroilGroup, meta: { transition: 'slide-left' },},
    {path: '/office-tour',name: 'office-tour', component: OfficeTour},
    {path: '/my-tools',name: 'my-tools', component: MyTools},
    {path: '/go-digital',name: 'go-digital', component: GoDigital},
    {path: '/go-digital/e-fes', name: 'go-digital-efes', component: EFes},
    {path: '/go-digital/fiori', name: 'go-digital-fiori', component: Fiori},
    {path: '/go-digital/intranet', name: 'go-digital-sharepoint', component: Sharepoint},
    {path: '/h-s',name: 'h-s', component: HS},
    {path: '/basic-policies',name: 'basic-policies', component: BasicPolicies},
    {path: '/my-hr',name: 'my-hr', component: myHR},
    {path: '/the-end', name: 'the-end', component: theEnd}

];

const router = new VueRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    mode: 'history',
    routes, // short for `routes: routes`
    scrollBehavior (to) {
        if (to.hash) {
            return {
                el: to.hash,
            }
        }else{
            return { x: 0, y: 0 }
        }

    }
});

export default router;