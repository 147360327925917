<template>

  <div id="image-lightbox" class="d-flex justify-content-center align-items-center">

    <div class="lightbox-backdrop" @click="closeLightbox">




    </div>

    <div class="image-wrapper">
      <img src="@/assets/close-btn.svg" width="40" class="close-btn cursor-pointer" @click="closeLightbox"/>

<!--      <image-zoom :regular="$store.state.lightboxImages[0]" class="image"/>-->
      <div class="image-wr dragscroll position-relative">
        <img id="image-zoom" :src="$store.state.lightboxImages[0]" loading="lazy"/>

      </div>


      <div class="d-flex position-absolute mx-auto" style="bottom:0;left:48%;right:auto;">
        <b-btn @click="zoomOut" class="mr-1" style="background-color:#182750;color:#fff;border:0;border-radius:0">-</b-btn>
        <b-btn variant="dark" @click="zoomIn" style="background-color:#182750;color:#fff;border:0;border-radius:0">+</b-btn>
      </div>



    </div>
  </div>

</template>

<style lang="scss">

#image-lightbox{
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index:9999;
  height:100%;
  .close-btn{
    position:absolute;
    top:30px;
    right:100px;
    z-index:999999;
  }
  .lightbox-backdrop{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:11;
    background: rgba(0,0,0,0.8);
  }
  .image-wrapper{
    position:relative;
    z-index:99999;
    max-width:90%;
    .image{
      max-height: 80vh;
    }

    background:white;
  }
}

.image-wr{
  min-width:90vw;
  width: 100%;
  height: 80vh;
  overflow: auto;
  cursor: grab;
  cursor: -o-grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  img{
    height:auto;
    width:100%;
    max-width:unset;
  }
}
</style>

<script>
export default{


  computed:{
    show(){
      return this.$store.state.showLightbox
    }
  },
  methods:{
    zoomIn() {
      let myImg = document.getElementById("image-zoom");
      let currWidth = myImg.clientWidth;
      if (currWidth === 2500) return false;
      else {
        myImg.style.width = (currWidth + 100) + "px";
      }
    },
    zoomOut() {
      let myImg = document.getElementById("image-zoom");
      let currWidth = myImg.clientWidth;
      if (currWidth <= 600) return false;
      else {
        myImg.style.width = (currWidth - 100) + "px";
      }
    },


  },
  mounted(){
    // eslint-disable-next-line no-undef
    if(dragscroll) dragscroll.reset()
  }

}
</script>