import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue} from "bootstrap-vue";
import router from "./router";
import store from "./store";
/* import the fontawesome core */
require('vue2-animate/dist/vue2-animate.min.css');
import '@/assets/styles/globals/_boostrap.scss';

Vue.config.productionTip = false


Vue.use(BootstrapVue);

Vue.mixin({
  methods:{
    redirectTo(url, newWindow = true){

      if(!newWindow) window.location.href = url
      else{
        window.open(url, '_blank')
      }
    },

    openLightbox(images){
      this.$store.commit('setLightboxImages', images);
      this.$store.commit('setShowLightbox', true);
    },
    closeLightbox(){
      this.$store.commit('setLightboxImages', []);
      this.$store.commit('setShowLightbox', false);
    },

    toggleFloatAnimations(){
      let animatedElems = document.querySelectorAll('.animate__animated');

      setTimeout(()=>{
        for(const [index, elem] of animatedElems.entries()){

          if(index %2 === 0) elem.classList.add('float-anim')
          else elem.classList.add('float-anim-reverse');

        }
      }, 1500);
    }
  }
})

new Vue({
  render: h => h(App),
  store: store,
  router: router,
}).$mount('#app')
