import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        showSidebar: false,
        showLightbox: false,
        lightboxImages: []
    },
    mutations: {
        setShowLightbox(state, val){
            state.showLightbox = val;
        },

        setLightboxImages(state, images){
            state.lightboxImages = [...images];
        },


        setShowSidebar(state, value){
            state.showSidebar = value;
            localStorage.setItem('showSidebar', JSON.stringify(value));
        }

    },

    actions:{
        setShowSidebar(context, val){
            context.commit('setShowSidebar', val);
        }
    }
});

export default store;