
<template>

  <div id="side-bar-wrap">
    <div class="side-bar-overlay" :class="showSidebar ? 'show' : ''" @click="hideSidebar"></div>
    <div id="side-bar" :class="showSidebar ? 'show' : ''" >

      <div class="close-btn text-right">
        <span v-html="close"  @click="hideSidebar"/>
      </div>

      <div class="p-3 menu-items mt-3">

        <div v-for="item in menuItems" :key="item.pathName" class="menu-item">

          <router-link :to="{name: item.pathName}" @click.native="hideSidebar" >
            {{item.title}}
          </router-link>

        </div>

      </div>

    </div>
  </div>

</template>


<style lang="scss">
.side-bar-overlay{
  z-index:10;
  position:fixed;
  visibility:hidden;
  opacity:0;
  transition: 0.5s all ease-in-out;
  &.show{
    opacity:100;
    visibility: visible;
  }
  top:0;
  left:0;
  right:0;
  bottom:0;
  background:rgba(0,0,0,0.6);
}
#side-bar{
  overflow-y:auto;
  z-index:9999;
  position:fixed;
  right:0;
  top:0;
  bottom:0;
  height:100%;
  background-color:#002d5d;
  text-align:left;
  transform: translateX(40vw);
  animation: all 1s ease;
  @media(max-width:767px){
    transform: translateX(100vw);
    padding:2rem;
  }
  padding: 4rem;
  .menu-items{
    //display:none;
    font-weight:800;
  }
  &.show{
    .menu-items{
      //display:block;
    }
    width:500px;
    animation: animate-side-menu 1s ease forwards;
    @media(max-width:767px){
      width:100%;
    }

  }
  .close-btn{
    cursor:pointer;
  }

  .menu-item{
    padding-bottom:1em;
    a{
      font-family: 'ZonaPro', sans-serif;
      font-weight:700!important;
      text-decoration:none!important;
      color:#fff;
      font-size:1.8em;

    }
    .router-link-exact-active{
        color:#B8203C;
    }
  }

  @keyframes animate-side-menu
  {
    0%
    {
      transform: translateX(40vw);
    }
    100%
    {
      transform: translateX(0);
    }
  }
}
</style>

<script>


import {mapState} from "vuex";
import {close} from "./svgIcons";

export default{

  computed:{
    ...mapState({
      showSidebar: state => state.showSidebar
    })
  },
  data(){
    return {
      close,
      menuItems: [
        {
          title: 'Home',
          pathName: 'home'
        },
        {
          title: 'Group Motor Oil',
          pathName: 'motoroil-group',
        },
        {
          title: 'Office Tour',
          pathName: 'office-tour',
        },
        {
          title: 'My Tools',
          pathName: 'my-tools',
        },
        {
          title: 'Go Digital',
          pathName: 'go-digital',
        },
        {
          title: 'H&S',
          pathName: 'h-s',
        },
        {
          title: 'Basic Policies',
          pathName: 'basic-policies',
        },
        {
          title: 'My HR',
          pathName: 'my-hr',
        }
      ]

    }
  },
  methods:{
    hideSidebar(){
      this.$store.commit('setShowSidebar', false);
    },

    handleClick(e){
      console.log(this);
      console.log(this.showSidebar)
      if(this.showSidebar){
        if (document.getElementById('side-bar').contains(e.target)){
          // Clicked in box
        } else{
          this.$store.commit('setShowSidebar', false);
        }
      }

    }
  },


}
</script>