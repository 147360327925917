<template>

  <div id="my-hr">

    <div class="my-hr-wrapper">
      <div class="circles" v-html="svgIcons.circles"/>

      <div class="container h-100 position-relative">
        <div class="row align-items-center pt-5">

          <div class="col-12">

            <h2 class="text-blue title">My HR</h2>
            <h5 class="text-white mt-4">we are HeRe</h5>

          </div>
          <div class="col-12 mt-5">
<!--            <img src="@/assets/my-hr/policies-main.png" loading="lazy" width="600"/>-->
          </div>
        </div>
      </div>

      <div class="container position-relative mb-5">

        <div class="row">

          <!-- <div class="col-12 my-3">
            <div class="inner-content" >
                <div class="inner-content-shadow">
                  <h3 class="inner-content-title text-left text-zonapro">
                    Οργανόγραμμα MoH HR
                  </h3>

                  <div class="position-relative">
                    <img src="@/assets/my-hr/17-moh-hr.jpg" class="cursor-pointer"  @click="openLightbox([require('@/assets/my-hr/17-moh-hr.jpg')])" loading="lazy"/>

                    <div class="position-absolute enlarge-image-box cursor-pointer"
                         style=""  @click="openLightbox([require('@/assets/my-hr/17-moh-hr.jpg')])" >
                      <img src="@/assets/enlarge-image.svg">
                    </div>
                  </div>

                </div>

              </div>

          </div> -->

          <div class="col-12 offset-md-3 col-md-9 my-3 mt-5">
            <div class="inner-content" >
              <div class="text-right">
                <h2 class="text-blue title w-75 mb-4">Learning & Development</h2>
              </div>
              <div class="inner-content-shadow large-margin-left position-relative">
                <img src="@/assets/my-hr/hr-stoxos.png" class="stoxos animate__animated animate__fadeInTopRight"/>
                <img src="@/assets/my-hr/hr-MyGoals.png" class="goals"/>

                <img src="@/assets/my-hr/hr-pita.png" class="pita animate__animated animate__fadeInLeft"/>
                <img src="@/assets/my-hr/hr-data.png" class="data animate__animated animate__fadeInBottomLeft"/>
                <h3 class="inner-content-title text-left text-zonapro mb-3">
                  Σύστημα Διαχείρισης Απόδοσης
                </h3>


                <div class="position-relative">
                  <video-screen-no-speakers video-url="https://edlservices.s3.eu-north-1.amazonaws.com/my-goals.mp4" :goals="true"></video-screen-no-speakers>

                </div>

                <div class="content mt-4">
                  <p>
                    To My Goals, εφαρμόζεται μέσα από το  SAP Success Factors, την πιο σύγχρονη και ολοκληρωμένη πλατφόρμα διαχείρισης απόδοσης ανθρώπινου δυναμικού.

                  </p>
                  <p>
                    Στη σελίδα αυτή μπορείτε να δείτε αναλυτικά τα στοιχεία του συστήματος, μέσα από ένα πρωτότυπο βίντεο αλλά και τα αντίστοιχα εγχειρίδια που το αφορούν.
                  </p>
                  <div class="text-left">
                    <b-button variant="red" class="cta-button" @click="redirectTo('https://motoroil.sharepoint.com/sites/el-HR-PerformaneManagement')">Μάθετε περισσότερα</b-button>
                  </div>

                </div>
              </div>
              <div class="inner-content-shadow large-margin-left position-relative" style="padding-bottom:150px;margin-bottom:105px;">

                <img src="@/assets/my-hr/hr-myLearningPath.png" class="goals"/>
                <img src="@/assets/my-hr/hr-learning-img.png" class="learning-img"/>

                <h3 class="inner-content-title text-left text-zonapro">
                  Σύστημα Εκπαίδευσης
                </h3>

                <div class="content mt-4">
                  <p>
                    Κάτω από το όνομα My Learning Path, δημιουργήθηκε μια σύγχρονη εκπαιδευτική πλατφόρμα στο περιβάλλον του Success Factors του SAP.
                    Παρακάτω μπορείτε να βρείτε το link για τις συνεργασίες μας με  μεγάλους εκπαιδευτικούς φορείς όπως το
                    Ηarvard Manage Mentor και το Linkedin Learning αλλά και χρήσιμα εγχειρίδια που θα κάνουν πιο εύκολη την περιήγηση σας στη διαθέσιμη εκπαιδευτική βιβλιοθήκη
                  </p>
                  <p>
                    Μπορείτε από τώρα να περιηγηθείτε στο My Learning Path, ακολουθώντας το παρακάτω link.
                  </p>
                  <p>
                    Εκεί, θα βρείτε τις απαραίτητες προς ολοκλήρωση εισαγωγικές εκπαιδεύσεις, οι οποίες θα διευκολύνουν την ένταξη σας στην ομάδα του ομίλου της Motor Oil.
                  </p>
                  <p>
                    Οι εκπαιδεύσεις θα πρέπει να έχουν ολοκληρωθεί σε διάστημα τεσσάρων εβδομάδων.
                  </p>
                  <div class="text-left">
                    <b-button variant="red" class="cta-button" @click="redirectTo('https://motoroil.sharepoint.com/sites/el-HR-LearningPath')" >Μάθετε περισσότερα</b-button>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-9 my-3">
            <div class="inner-content" >
              <div class="inner-content-shadow">
                <img src="@/assets/my-hr/hr-med.png" class="med animate__animated animate__fadeInUp"/>
                <h3 class="inner-content-title text-left text-zonapro">
                  Παροχές Υγείας & Ασφάλισης
                </h3>


                <div class="content mt-4 w-75">

                 <p>
                   Στο συγκεκριμένο portal θα ενημερωθείτε για το ομαδικό πρόγραμμα ασφάλισης και Νοσοκομειακής περίθαλψης, για τις παροχές
                   από το Mediterraneo Hospital  καθώς και για τη δυνατότητα παροχής  περιοδικού check up.
                 </p>
                  <div class="text-left">
                    <b-button variant="red" class="cta-button"
                    @click="redirectTo('https://motoroil.sharepoint.com/sites/el-HR/SitePages/Compensations-%26-Benefits.aspx')">Μάθετε περισσότερα</b-button>
                  </div>

                </div>
              </div>

            </div>
          </div>
          <div class="col-12 col-md-9 my-3">
            <div class="inner-content" >
              <div class="inner-content-shadow">
                <img src="@/assets/my-hr/hr-covid.png" class="covid animate__animated animate__fadeInRight"/>
                <h3 class="inner-content-title text-left text-zonapro">
                  Covid-19
                </h3>


                <div class="content mt-4 w-75">
                  <p>
                    Στο συγκεκριμένο portal μπορείτε να βρείτε  χρήσιμες πληροφορίες σχετικά  με το Emergency Response Plan
                    του Ομίλου καθώς και μέτρα πρόληψης και αντιμετώπισης του ιού.
                  </p>
                  <div class="text-left">
                    <b-button variant="red" class="cta-button" @click="redirectTo('https://motoroil.sharepoint.com/sites/CrisisManagement')">Μάθετε περισσότερα</b-button>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="col-12 offset-md-1 col-md-10 my-3 " >
            <div class="inner-content" style="padding-bottom:30px;">
              <div class="inner-content-shadow large-margin-left">
                <img src="@/assets/my-hr/hr-discounts.png" class="discounts animate__animated animate__fadeInLeft"/>
                <h3 class="inner-content-title text-left text-zonapro">
                  Οι εκπτώσεις μας
                </h3>


                <div class="content mt-4 w-75">

                  <p>
                    Εδώ θα βρείτε τις ειδικές προσφορές που παρέχει ο  όμιλος  στους εργαζόμενους του και που αφορούν, μεταξύ άλλων,  προϊόντα και υπηρεσίες ενέργειας.
                  </p>
                  <div class="text-left">
                    <b-button variant="red" class="cta-button" @click="redirectTo('https://motoroil.sharepoint.com/sites/el-HR/SitePages/%CE%9F%CE%B9-%CE%B5%CE%BA%CF%80%CF%84%CF%8E%CF%83%CE%B5%CE%B9%CF%82-%CE%BC%CE%BF%CF%85.aspx')">
                      Μάθετε περισσότερα</b-button>
                  </div>

                </div>
              </div>

            </div>
          </div>

          <div class="col-12 col-md-12 my-3 mt-5">
            <div class="inner-content" >
              <div class="ml-5 text-left">
                <h2 class="text-blue title w-75 mb-4" style="margin-left:5rem">Giving Back</h2>
              </div>
              <div class="inner-content-shadow  large-paddings position-relative">

                <img src="@/assets/my-hr/hr-giving-back.png" class="giving-back"/>
                <img src="@/assets/my-hr/giving-heart.png" class="giving-heart"/>

                <div class="row">
                  <div class="col-12 col-md-6 pr-5">
                    <h3 class="inner-content-title text-left text-zonapro">
                      Εταιρικές Δράσεις
                    </h3>


                    <div class="content mt-4">
                      <p>
                        Ο Όμιλος Μοτορ Οιλ διοργανώνει αθλητικές δραστηριότητες, εκδηλώσεις και εκδρομές για τις οποίες μπορείτε να ενημερωθείτε και να λάβετε μέρος ακολουθώντας το παρακάτω link.
                      </p>
                      <div class="text-left">
                        <b-button variant="red" class="cta-button" @click="redirectTo('https://motoroil.sharepoint.com/sites/el-HR#%CE%B5%CF%84%CE%B1%E2%80%8B%E2%80%8B%E2%80%8B%E2%80%8B%E2%80%8B%E2%80%8B%E2%80%8B%CE%B9%CF%81%CE%B9%CE%BA%CE%AD%CF%82-%CE%B4%CF%81%CE%AC%CF%83%CE%B5%CE%B9%CF%82')">Μάθετε περισσότερα</b-button>
                      </div>
                    </div>

                  </div>
                  <div class="col-12 col-md-6 pr-5">
                    <h3 class="inner-content-title text-left text-zonapro">
                      Τράπεζα Αίματος
                    </h3>


                    <div class="content mt-4">

                      <p>
                        Ο Όμιλος Motor Oil έχει ξεκινήσει την εθελοντική αιμοδοσία στο σύνολο των εργαζομένων της, εδώ καi 18 χρόνια.
                      </p>
                      <p>
                        Κάθε αιμοδοσία που διοργανώνεται είναι σημαντική και απαραίτητη για όλους τους εργαζομένους και τις οικογένειές τους.
                      </p>
                      <p>
                        Η Τράπεζα αίματος που έχει δημιουργηθεί όλα αυτά τα χρόνια από την εθελοντική συνεισφορά όλων μας,
                        έχει ως στόχο να καλύψει τις ανάγκες μας σε όποιο νοσοκομείο και αν είμαστε, στην Ελλάδα ή στο εξωτερικό.
                      </p>
                      <div class="text-left">
                        <b-button variant="red" class="cta-button" @click="redirectTo('https://motoroil.sharepoint.com/sites/el-HR/SitePages/%CE%91%CE%B9%CE%BC%CE%BF%CE%B4%CE%BF%CF%83%CE%AF%CE%B1.aspx')">
                          Μάθετε περισσότερα</b-button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


      <div class="row next-section-wrapper py-5" style="padding-bottom:100px;!important">

        <img src="@/assets/office-tour/diilistirio.png" class="diilistirio"/>
        <img src="@/assets/office-tour/konkat.png" class="konkat"/>
        <div class="col-12 col-md-6 text-right">

          <prev-section-btn text="Basic Policies" to-url="basic-policies"></prev-section-btn>
        </div>
        <div class="col-12 col-md-6 text-left">

          <next-section-btn text="The End" to-url="the-end"></next-section-btn>
        </div>
      </div>
    </div>


  </div>
</template>

<style lang="scss">
#my-hr{
  overflow-x:hidden;
  position:relative;
  min-height:100vh;
  background: linear-gradient(0deg, #5880FF 0%, #6E98FF 66%, #A2D0FF 100%);
  .my-hr-wrapper{
    height:100%;
    position:relative;
    max-width:1440px;
    margin:0 auto;
  }
  .stoxos{
    position:absolute;
    width:130px;
    top:-60px;
    right:-90px;
  }
  .goals{
    position:absolute;
    width:300px;
    top:-50px;
    left:-150px;
  }

  .data{
    position:absolute;
    width:90px;
    top:200px;
    left:-50px;
  }
  .pita{
    position:absolute;
    width:90px;
    top:500px;
    left:50px;
  }

  .learning-img{
    position:absolute;
    width:400px;
    bottom:-150px;
    right:-60px;
  }
  .med{
    position:absolute;
    width:120px;
    right:-70px;
    bottom:35%;
  }
  .covid{
    position:absolute;
    width:120px;
    right:-70px;
    bottom:30%;
  }
  .discounts{
    position:absolute;
    width:200px;
    left:-110px;
    top:50px;
  }

  .giving-back{
    position:absolute;
    width:150px;
    left:-60px;
    top:-175px;
  }

  .giving-heart{
    width:250px;
    left:0;
    bottom:25px;
    position:absolute;
  }
  .inner-content-title{
    font-size:20px;
  }


  .inner-content-shadow{
    padding:3rem 5rem;
    max-width:100%;
    &.large-paddings{
      padding:5rem 8rem;
    }
    &.large-margin-left{
      padding:3rem 5rem 3rem 13rem;
    }
    .content{
      p{
        text-align:justify;
        font-size:.9rem!important;
      }
    }
  }
  .inner-content{
    height:100%;

  }

  .next-section-wrapper{
    max-width:1400px;
    margin:0 auto;
    position:relative;
    padding: 7rem 0 15rem;
    .diilistirio{
      position: absolute;
      bottom:0;
      width:350px;
      left:6%;
    }
    .konkat {
      position: absolute;
      bottom:0;
      width:225px;
      right:10%;
    }

  }


}
</style>
<script>
import PrevSectionBtn from "../components/PrevSectionBtn";
import svgIcons from "../components/svgIcons"
import NextSectionBtn from "../components/NextSectionBtn";
import VideoScreenNoSpeakers from "../components/VideoScreenNoSpeakers";
export default{

  components:{VideoScreenNoSpeakers, NextSectionBtn, PrevSectionBtn},
  data(){
    return{
      svgIcons
    }
  },
  mounted(){
    this.toggleFloatAnimations();
  }
}
</script>
