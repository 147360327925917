<template>
  <b-button variant="red" class="next-section-btn" @click="redirectTo">
    {{ text }} <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_58_2664)">
      <path d="M13.1253 0.332729C13.0214 0.434745 12.9388 0.556168 12.8825 0.690093C12.8262 0.824019 12.7972 0.96767 12.7972 1.11281C12.7972 1.25795 12.8262 1.40192 12.8825 1.53585C12.9388 1.66977 13.0214 1.79119 13.1253 1.89321L16.1881 4.90929H1.13713C0.991025 4.90842 0.846187 4.9364 0.711032 4.99154C0.575876 5.04668 0.453111 5.128 0.349795 5.23063C0.246478 5.33326 0.164696 5.45533 0.109184 5.58959C0.0536732 5.72385 0.0255145 5.8678 0.0263869 6.01294C0.0255205 6.15925 0.053783 6.30407 0.109545 6.43949C0.165308 6.5749 0.24748 6.69819 0.351319 6.80195C0.455158 6.90571 0.578612 6.98805 0.714602 7.04423C0.850591 7.10042 0.99643 7.12935 1.14371 7.12935H16.2144L13.1516 10.1196C13.0479 10.2211 12.9655 10.342 12.9092 10.4754C12.853 10.6088 12.8241 10.7519 12.8241 10.8965C12.8241 11.0411 12.853 11.1842 12.9092 11.3176C12.9655 11.451 13.0479 11.5719 13.1516 11.6734C13.3608 11.8787 13.6429 11.9938 13.937 11.9938C14.231 11.9938 14.5133 11.8787 14.7224 11.6734L19.7241 6.78984C19.8167 6.69824 19.8902 6.58933 19.9403 6.46945C19.9904 6.34957 20.0163 6.22086 20.0163 6.09105C20.0163 5.96123 19.9904 5.83284 19.9403 5.71296C19.8902 5.59308 19.8167 5.48418 19.7241 5.39258L14.7027 0.332729C14.5999 0.228498 14.4772 0.145702 14.3417 0.0891727C14.2063 0.032643 14.0609 0.00341797 13.914 0.00341797C13.7671 0.00341797 13.6217 0.032643 13.4863 0.0891727C13.3508 0.145702 13.2281 0.228498 13.1253 0.332729Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_58_2664">
        <rect width="20" height="12" fill="white"/>
      </clipPath>
    </defs>
  </svg>

  </b-button>

</template>
<style lang="scss" scoped>
.next-section-btn{
  position:relative;
  box-shadow: 0.2rem 0.2rem rgba(0,0,0,0.16);
  z-index:10;
  &:hover{
    transform:scale(1.05);
  }
  svg{
    margin-left:.5rem;
  }
}
</style>

<script>
export default{

  props:{
    toUrl: String,
    text: String
  },

  methods:{
    redirectTo(){
      this.$router.push({name: this.toUrl});
    }
  }
}
</script>