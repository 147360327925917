export default{

    itHelpdesk: {
        title:'IT Helpdesk',
        content: '<p>Η ομάδα του Ηelpdesk  μπορεί να σας εξυπηρετήσει άμεσα και αποτελεσματικά σε οποιοδήποτε τεχνικό θέμα αντιμετωπίσετε.</p>' +
            '<p>Μπορείτε να επικοινωνήσετε με κάποιον εξειδικευμένο τεχνικό είτε μέσω του ΙΤ Helpdesk Line τηλεφωνικά είτε μέσω της πλατφόρμας Ticketing Service του SNOW.</p>' +
            '<p>Τηλ: <strong>2108093350</strong></p>',
    },
    snow:{
        content: '<p>Η πλατφόρμα SNOW – Service Now είναι ένα εργαλείο που περιλαμβάνει τις IT ανάγκες των χρηστών και αποτελεί ένα τρόπο εξυπηρέτησης και υποστήριξης των αναγκών μας σε επίπεδο Ομίλου.</p>' +
            '<p>Μέσω αυτής της πλατφόρμας γίνεται</p><ul class="text-left"><li>Εύκολη η καταχώρηση αιτημάτων από τους χρήστες μέσω όλων των εταιρικών συσκευών (PC, κινητό ή tablet μέσω εφαρμογής), τηρώντας όλες τις πολιτικές ασφαλείας του οργανισμού.</li><li>Αυτόματη ανάθεση του κάθε αιτήματος στην κατάλληλη ομάδα διαχείρισης, αποφεύγοντας καθυστερήσεις κατηγοριοποίησης και αναζήτησης τεχνικού.</li>' +
            '<li>Κατάλογος διαθέσιμων υπηρεσιών (service catalogue) από τις οποίες θα μπορεί ο χρήστης να επιλέξει αυτή που θέλει.</li></ul><div><br></div>'
    },
    meetings:{
        title: 'Αίθουσες Συνεδριάσεων',
        content: '<p>Σε περίπτωση που θέλετε να δεσμεύσετε μια αίθουσα συνεδριάσεων στα κεντρικά γραφεία, θα πρέπει να καλέσετε την κα Μπακαλοπούλου Χριστίνα η οποία θα σας ενημερώσει εάν η αίθουσα είναι διαθέσιμη και θα την δεσμεύσει για εσάς.</p><p>Όσον αφορά το κτήριο της Νrg θα πρέπει να απευθυνθείτε στην κα Μαυριδάκη Αθηνά.</p>',
        items: [
            {
                company: 'MoH',
                name: 'Μπακαλοπούλου Χριστίνα',
                phone: '3772',
                email: 'xmpakalopoulou@konkat.gr'
            },
            {
                company: 'Νrg',
                name: 'Μαυριδάκη Αθανασία',
                email: 'amavridaki@nrg.gr'
            }
        ]
    },
    cars: {
        title: 'Εταιρικά Αυτοκίνητα',
        content: '<p>Σχετικά με τον εξοπλισμό, τη συντήρηση και την επισκευή του οχήματος σας μπορείτε να επικοινωνήσετε απευθείας με την εταιρεία μίσθωσης σας.</p>' +
            '<p>Παρακάτω θα βρείτε τα στοιχεία επικοινωνίας:</p>' +
            '<p>ARVAL: <b>210 8772600</b></p><p>LEASEPLAN: <b>210 6100050</b></p><p>HERTZ ΕΚΤΑΚΤΗ ΑΝΑΓΚΗ: <b>210 6264999</b></p><p>HERTZ ΣΥΝΤΗΡΗΣΗ ΟΧΗΜΑΤΟΣ: <b>210 8003900</b></p><p>ALD: <b>210 484000</b></p>' +
            '<p>Για οτιδήποτε άλλο χρειαστείτε η  ομάδα του HR Support μπορεί να σας ενημερώσει σχετικά και να επιλύσει οποιαδήποτε απορία σας.  </p><p>Επικοινωνία: <b>Χριστοφοράκης Βαγγέλης</b></br>Τηλ: <b>210 8093775</b></br>Email: <b>echristoforakis@moh.gr</b>&nbsp;&nbsp;</p>'
    },
    phones:{
        title: 'Εταιρικά τηλέφωνα',
        content: '<p class="text-left">Στα πλαίσια της αποτελεσματικότερης παροχής υπηρεσιών στους εργαζομένους μας, οι οποίοι διαθέτουν εταιρικό κινητό, δίνονται παρακάτω τα στοιχεία επικοινωνίας των συναδέλφων οι οποίοι ' +
            'μπορούν να σας κατευθύνουν σχετικά με την παροχή συσκευής αλλά και το πακέτο του λογαριασμού σας.</p>',
            // '<p>Επικοινωνία: <b>Στεριώτης Θωμάς</b><br>Τηλ: <b>210 9476110</b><br>Email: <b>tsteriotis@moh.gr</b>&nbsp;&nbsp;</p>',
        items: [
            {
                company: 'MoH, More',
                name: 'Σωκρατίδης Αναστάσιος',
                phone: '210 80940213',
                email: 'sokratan@moh.gr'
            },
            {
                company: 'Coral, Coral In',
                name: 'Γιδίτση Γεωργία',
                phone: '210 94 76 268',
                email: 'georgia.giditsi@ceg.gr'
            },
            {
                company: 'Avin',
                name: 'Μίαρης Αλέκος',
                phone: '210 8093582',
                email: 'miari.al@avinoil.gr'
            },
            {
                company: 'LPC',
                name: 'Σγαντζόνυχας Δημήτριος',
                phone: '6936677398',
                email: 'dsgantzonychas@lpc.gr'
            },
        ]
    }
}
