<template>

  <div id="fiori-go-digital">

    <div class="fiori-digital-wrapper">
      <div class="circles" v-html="svgIcons.circles"/>
      <div class="container h-100 position-relative mb-5">
        <div class="row align-items-center pt-5">

          <div class="col-12">

            <h2 class="text-blue title">Go Digital</h2>
            <h5 class="text-white mt-4">Τα συστήματά μας</h5>

          </div>
        </div>
      </div>

      <div class="tv-box">
        <video-screen-no-speakers
            :key="activeIndex"
            :video-url="videos[activeIndex]"></video-screen-no-speakers>
        <div class="mt-4 position-relative">
          <b-button variant="primary" @click="activeIndex = 0"
                    :disabled="activeIndex === 0" class="text-zonapro" style="border-radius:25px;">Employee Navigation</b-button>
          <b-button variant="primary" @click="activeIndex = 1" class="ml-2 text-zonapro" style="border-radius:25px;"
                    :disabled="activeIndex === videos.length -1">Manager Navigation</b-button>

        </div>

      </div>

      <div class=" central-box">

        <img src="@/assets/go-digital/fiori/gd-fiori-calendar.png" class="calendar animate__animated animate__fadeInTopLeft"/>
        <img src="@/assets/go-digital/fiori/gd-fiori-clock.png" class="clock animate__animated animate__fadeInRight"/>

        <div class="central-box-content">

          <h3 class="inner-content-title text-literata text-white mb-5">
            SAP Fiori
          </h3>


          <div class="row">
            <div class="col-12 col-md-6">
              <div class="content-box text-left">
                <p class="text-white mb-5">
                  Πρόκειται για την πλατφόρμα διαχείρισης θέματων προσωπικού μέσω του οποίου κάθε εργαζόμενος έχει στη διάθεσή του τις  εξής εφαρμογές:
                </p>
                <h5 class="inner-content-title text-zonapro text-white mb-4">
                  My Leave Request
                </h5>
                <p class="text-white">
                  Επιλέγοντας την εφαρμογή “my leave Request” μπορείτε να δημιουργήσετε και να υποβάλλεται αιτήσεις αδειών γρήγορα και ευέλικτα
                </p>
              </div>
              <div class="content-box text-left">
                <h5 class="inner-content-title text-zonapro text-white mb-4">
                  My Time Events
                </h5>
                <p class="text-white">
                  Στην περίπτωση που δεν έχει καταχωρηθεί, για κάποιο λόγο, χτύπημα εισόδου/εξόδου και λάβετε email για την προβληματική ημέρα, μπορείτε να χρησιμοποιήσετε
                  την εφαρμογή my time events προκειμένου να την καταχωρήσετε.</p>
              </div>
              <div class="content-box text-left">
                <h5 class="inner-content-title text-zonapro text-white mb-4">
                  My Team Calendar
                </h5>
                <p class="text-white">
                  Στη συγκεκριμένη εφαρμογή μπορείτε να δείτε τις επερχόμενες άδειες, εκδηλώσεις, εκπαιδευτικά προγράμματα και το χρονοδιάγραμμα τους, για το σύνολο της ομάδας σας.
                </p>
              </div>


              <div class="content-box text-left">
                <h5 class="inner-content-title text-zonapro text-white mb-4">
                  My Paystubs
                </h5>
                <p class="text-white">
                  Με την επιλογή My Paystubs έχετε την δυνατότητα προβολής και εξαγωγής της απόδειξης πληρωμής του μήνα που θα επιλέξετε.
                </p>
              </div>


              <div class="mt-5">
                <b-button variant="red" class="cta-button" @click="redirectTo('https://motoroil.sharepoint.com/sites/el-MotorOil')">Go to Fiori</b-button>
                <p class="text-white font-italic w-75 mx-auto mt-2" style="font-size:12px;">
                  Πατώντας το παραπάνω Link θα μεταφερθείτε αυτόματα στο περιβάλλον του intranet από όπου μπορείτε να εισέλθετε στο Fiori.
                </p>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="content-box">
                <img src="@/assets/go-digital/fiori/gd-fiori-pc.png" class="pc"/>
              </div>
              <div class="content-box text-left">
                <h5 class="inner-content-title text-zonapro text-white mb-4">
                  Employee Lookup
                </h5>
                <p class="text-white">
                  Η  εφαρμογή  «Employee Look up» επιτρέπει  στον  χρήστη  είτε  την  αναζήτηση  συναδέλφων, χρησιμοποιώντας απλά το ονοματεπώνυμο τους είτε την εμφάνιση των δικών του δεδομένων.
                </p>
                <p class="text-white">
                  Μέσω της εφαρμογής προβάλλονται οργανωτικά στοιχεία και στοιχεία επικοινωνίας, η γραμμή αναφοράς του επιλεγμένου εργαζόμενου καθώς και οι εργαζόμενοι που αναφέρονται άμεσα σε αυτόν.
                </p>
              </div>
              <div class="content-box text-left">
                <h5 class="inner-content-title text-zonapro text-white mb-4">
                  People Profile
                </h5>
                <p class="text-white">
                  Με την εφαρμογή People Profile  έχετε τη δυνατότητα να εμφανίσετε τη σύνοψη των δικών σας δεδομένων, τα οργανωτικά στοιχεία και τα στοιχεία επικοινωνίας καθώς
                  και να περιηγηθείτε στην ιεραρχία των εργαζομένων μέσα από την εφαρμογή του «Employee Lookup».  </p>
                <p class="text-white">
                  Στη σελίδα αυτή μπορείτε να βρείτε αναλυτικές οδηγίες και videos τα οποία εξηγούν τη χρήση και τις δυνατότητες του συστήματος Fiori του Ομίλου Motor Oil.
                </p>
              </div>
            </div>

          </div>



        </div>


      </div>

      <div class="row next-section-wrapper ">
        <div class="col-12 text-center">

          <prev-section-btn text="Go Digital" to-url="go-digital"></prev-section-btn>
        </div>
      </div>
    </div>



  </div>

</template>

<style lang="scss">
#fiori-go-digital{

  .next-section-wrapper{
    padding-top:5rem;
    padding-bottom:10rem;
  }

  overflow-x:hidden;
  position:relative;
  background: linear-gradient(0deg, #A2D0FF 0%, #6E98FF 66%, #5880FF 100%);

  .fiori-digital-wrapper{
    position:relative;
    max-width:1440px;
    margin:0 auto;
    .gear-left{
      position:absolute;
      top:25%;
      left: 0;
      width:120px;
    }
    .gear-right{
      position:absolute;
      top:40%;
      right: 0;
      width:70px;
    }
  }
  .text-blue{
    color:#002d5d;
  }

  .central-box{
    background-color: #192C5520;
    position:relative;
    z-index:1;
    padding: 7rem 4rem;
    width:1100px;
    margin:0 auto;

    .calendar{
      position:absolute;
      width:170px;
      top:-60px;
      left:-45px;

    }
    .clock{
      position:absolute;
      width:120px;
      top:-30px;
      right:-35px;

    }


    .content-box{
      max-width:80%;
      margin:0 auto 3rem auto;
    }
    p{

      text-align:justify;
      font-size:0.9rem;
    }
  }


  .tv-box{
    margin-bottom:7.5rem;
    .tv{
      width:950px;
    }
  }
}
</style>

<script>
import svgIcons from "../../components/svgIcons"
import PrevSectionBtn from "../../components/PrevSectionBtn";
import VideoScreenNoSpeakers from "../../components/VideoScreenNoSpeakers";
export default{

  scrollToTop: true,
  components:{VideoScreenNoSpeakers, PrevSectionBtn},
  data(){
    return{
      svgIcons,
      activeIndex: 0,
      videos: ['https://edlservices.s3.eu-north-1.amazonaws.com/MOH+Fiori+Employee+Navigation+Video.mp4',
        'https://edlservices.s3.eu-north-1.amazonaws.com/MOH+Fiori+Manager+Navigation+Video.mp4'],

    }
  },
  mounted(){
    this.toggleFloatAnimations();
  }
}
</script>