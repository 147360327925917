<template>

  <div id="go-digital">

    <div class="go-digital-wrapper">
      <div class="circles" v-html="svgIcons.circles"/>
      <div class="container h-100 position-relative mb-5">
        <div class="row align-items-center pt-5">

          <div class="col-12">

            <h2 class="text-blue title">Go Digital</h2>
            <h5 class="text-white mt-4">Τα συστήματά μας</h5>

          </div>
        </div>
      </div>


      <div class=" central-box">
        <img src="@/assets/go-digital/gd-granazi1.png" class="gear-top animate__animated animate__fadeInTopLeft"/>
        <img src="@/assets/go-digital/gd-granazi2.png" class="gear-bottom animate__animated animate__fadeInBottomRight"/>
        <img src="@/assets/go-digital/gd-central.png" class="gear-middle"/>

        <div class="fiori-btn-wrap">
          <div class="animated-arrow-down fiori-arrows-down">
            <span></span>
            <span></span>
            <span></span>

          </div>
          <b-button variant="red" class="cta-button fiori-btn" style="margin-top:75px" @click="$router.push({name:'go-digital-fiori'})">SAP Fiori</b-button>
        </div>
        <div class="efes-btn-wrap">
          <div class="animated-arrow-down fiori-arrows-down">
            <span></span>
            <span></span>
            <span></span>

          </div>
          <b-button variant="red" class="cta-button efes-btn"  style="margin-top:75px" @click="$router.push({name:'go-digital-efes'})">e-Fes</b-button>
        </div>
        <div class="sharepoint-btn-wrap">
          <div class="animated-arrow-down fiori-arrows-down">
            <span></span>
            <span></span>
            <span></span>

          </div>
          <b-button variant="red" class="cta-button sharepoint-btn" style="margin-top:75px" @click="$router.push({name:'go-digital-sharepoint'})">Intranet</b-button>
        </div>

        <div class="bg-left"></div>
        <div class="bg-right"></div>

      </div>


      <div class="paragraph-after-box">


        <p class="text-white" style="font-size:0.9rem">
          Στην ενότητα αυτή  θα μπορέσετε να λάβετε όλες τις χρήσιμες πληροφορίες σχετικά με τα βασικά
          εξελιγμένα συστήματα του ομίλου μας τα οποία υπάρχουν στην καθημερινότητα όλων μας κι ευθυγραμμίζονται με τη στρατηγική ψηφιακού μετασχηματισμού
          του Ομίλου μας.

        </p>

      </div>


      <div class="row next-section-wrapper mt-5 ">
        <div class="col-12 col-md-6 text-right">

          <prev-section-btn text="My Tools" to-url="my-tools"></prev-section-btn>
        </div>
        <div class="col-12 col-md-6 text-left">

          <div class="next-section">

            <next-section-btn text="H&S" to-url="h-s"></next-section-btn>
          </div>
        </div>
      </div>

      <img src="@/assets/go-digital/gd-woman-bottom-left.png" class="woman-left"/>
      <img src="@/assets/go-digital/gd-man-bottom-right.png" class="man-right"/>
    </div>



  </div>

</template>

<style lang="scss">
#go-digital{

  .animated-arrow-down{
    top:10%;
    span{
      width:20px;
      height:20px;
    }
  }
  .next-section-wrapper{
    padding-bottom:10rem;
  }

  overflow-x:hidden;
  position:relative;
  background: linear-gradient(0deg, #A2D0FF 0%, #6E98FF 66%, #5880FF 100%);

  .go-digital-wrapper{
    .arrow-down{
      width:30px;
    }
    position:relative;
    max-width:1440px;
    margin:0 auto;
    .woman-left{
      position:absolute;
      width:100px;
      bottom:0;
      left: 20%;
    }
    .man-right{
      position:absolute;
      width:250px;
      bottom:0;
      right: 9%;
    }
  }

  .paragraph-after-box{
    max-width:700px;
    margin: 5rem auto;
    margin-top:5rem;
    text-align:left;
    p{
      text-align:justify;
    }
  }

  .central-box{
    background-color: #192C5520;
    position:relative;
    padding: 2rem;
    width:900px;
    margin:0 auto;
    height:600px;

    .bg-left{
      background-image: url('~@/assets/go-digital/gd-central-bg-left.png');
      background-size:cover;
      background-repeat:no-repeat;
      background-position:center left;
      width:50%;
      position:absolute;
      top:0;
      bottom:0;
      z-index:0;
      left:0;
    }
    .bg-right{
      z-index:0;
      background-image: url('~@/assets/go-digital/gd-central-bg-right.png');
      background-size:cover;
      background-repeat:no-repeat;
      background-position:center right;
      width:50%;
      position:absolute;
      top:0;
      bottom:0;
      right:0;
    }
    .gear-top{
      z-index:1;
      position:absolute;
      top:-40px;
      left:-40px;
      width:100px;
    }
    .gear-bottom{
      width:75px;
      z-index:1;
      position:absolute;
      bottom:-30px;
      right:-30px;
    }
    .gear-middle{
      width:63%;
      z-index:1;
      position:absolute;
      bottom:15%;
      left:20%;
    }

    .fiori-btn-wrap{
      z-index:1;
      position:absolute;

      .btn{
        width:100%;
      }
      width:150px;
      top:20%;
      left:-5%;
    }
    .efes-btn-wrap{
      z-index:1;
      position:absolute;
      .btn{
        width:100%;
      }
      width:150px;
      top:7%;
      left:45%;
    }
    .sharepoint-btn-wrap{
      z-index:1;
      position:absolute;
      .btn{
        width:100%;
      }
      width:200px;
      top:25%;
      right:-10%;
    }
  }

  .fiori-arrows-down{

  }
  .text-blue{
    color:#002d5d;
  }
}
</style>

<script>
import svgIcons from "../../components/svgIcons"
import NextSectionBtn from "../../components/NextSectionBtn";
import PrevSectionBtn from "../../components/PrevSectionBtn";
export default{
  components:{NextSectionBtn, PrevSectionBtn},
  data(){
    return{
      svgIcons
    }
  },
  mounted(){
    setTimeout(this.scrollToTop, 300);
    this.toggleFloatAnimations();
  },
  methods:{
    scrollToTop(){
      this.$nextTick(()=>{
        window.scrollTo(0,0);
      })

    }
  },

}
</script>