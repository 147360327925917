<template>
  <div id="home2" class="h-100 the-end">
    <img class="floor2" src="@/assets/images/patoma.png"/>
    <div class="home-container">
      <div class="circles" v-html="svgIcons.circles"/>

      <div class="patoma1">
        <div class="texts-floor1">
          <h2 class="title-blue">The End</h2>

          <h5 class="mt-4">The End of a new Start</h5>
          <img class="stoxos animate__animated animate__fadeInDown" src="@/assets/images/stoxos.png" width="100"/>
          <p class="text-white mt-5" style="margin-bottom:0.7rem">
            Ευχαριστούμε για την προσοχή σας!
          </p>
          <p class="text-white">
            Καλώς ήρθατε στην Motor Oil. Είμαστε χαρούμενοι που είστε μέλος της ομάδας μας!
          </p>
        </div>


      </div>
      <div class="patoma3 patoma2">
        <img class="main-character" src="@/assets/the-end-main.png"/>
        <img class="chart2 animate__animated animate__fadeInUp" src="@/assets/images/statistic.png"/>

        <img class="gear-big animate__animated animate__fadeInTopLeft" src="@/assets/images/gear-big.png"/>
        <img class="gear-small animate__animated animate__fadeInBottomLeft" src="@/assets/images/gear-small.png"/>


        <img class="gear-big2 animate__animated animate__fadeInRight" src="@/assets/images/gear-big.png"/>
        <img class="gear-small2 animate__animated animate__fadeInTopRight" src="@/assets/images/gear-small.png"/>
<!--        <img class="floor" src="@/assets/images/patoma.png"/>-->

      </div>
      <div class="patoma4">
        <img class="cargoboat" src="@/assets/images/boat.png"/>
        <img class="diilistirio" src="@/assets/images/diilistirio.png"/>

        <img class="ktirio" src="@/assets/images/ktirio.png"/>

      </div>




    </div>

    <img class="sea" src="@/assets/images/sea.png" width="100%"/>
  </div>

</template>

<style lang="scss">
.the-end{

  .floor2{
    top:$floor-height * 2.4 - 15!important;
  }
  .patoma1{
    .stoxos{
      position:absolute;
      top:60px;
      left:30px;
    }
  }
  .patoma2{
    height:$floor-height*1.4!important;
    .chart2{
      left:unset!important;
      right:270px!important;
      top:unset!important;
      bottom:-40px!important;
      width:75px!important;

    }
    .gear-big{
      top:20%;
      left:8%;
      width:60px;
    }
    .gear-small{
      top:45%;
      left:12.5%;
      width:30px;
    }
    .gear-big2{
      position: absolute;
      top:15%;

      right:5%;
      width:60px;
    }
    .gear-small2{
      position: absolute;
      top:0;
      right:10%;
      width:30px;
    }
  }


}
</style>
<script>
import svgIcons from "../components/svgIcons";

export default{

  components: {},
  computed:{
  },

  data(){
    return{
      svgIcons,
      currIndex: 0
    }
  },
  mounted(){
    this.toggleFloatAnimations();
  },
  methods:{
  }
}


</script>