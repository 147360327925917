<template>
  <header id="header" class="p-1">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 text-center logo">
          <router-link to="/">
            <span v-html="logo"/>
          </router-link>
          <div @click="displaySidebar" style="float:right;padding-top:2em;cursor:pointer">
            <span v-html="hamburger"/>
          </div>

          </div>
      </div>
    </div>

    <div v-html="triangle" class="triangle"></div>

  </header>
</template>

<script>

import {mapState} from "vuex";
import {triangle, hamburger, logo} from "./svgIcons";

export default{

  computed:{
    ...mapState({
      showSidebar: state => state.showSidebar
    })
  },
  data(){
    return{
      triangle,
      hamburger,
      logo
    }
  },
  methods:{

    displaySidebar(){
      this.$store.commit('setShowSidebar', true);
    }

  }


}
</script>
<style lang="scss">
#header{
  position:relative;
  background:#fff;
  z-index:5;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  .logo{
    svg{
      max-width:300px;
      @media(max-width:767px){
        max-width:180px;
      }
    }
  }
  a{
    font-size:1.2em;
    font-weight:400;
    margin-right:1em;
    text-decoration:none;
  }
  .triangle{
    position:relative;
    z-index:0;
    margin-bottom:-20px;

  }
}
</style>