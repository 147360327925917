<template>

  <div id="motoroil-group">
    <div class="motoroil-group-first-part">

      <div class="general-container">
        <div class="row pt-5">
          <div class="col-12">
            <h2 class="text-red text-literata">
              Group Motor Oil
            </h2>
            <h5 class="text-white mb-2 mt-4">
              Ready. Set. Get to know us…..
            </h5>

            <video-screen video-url="https://edlservices.s3.eu-north-1.amazonaws.com/O+%CE%8C%CE%BC%CE%B9%CE%BB%CE%BF%CF%82+Motor+Oil%2C+%CF%83%CF%85%CE%BD%CE%B5%CF%87%CE%AF%CE%B6%CE%B5%CE%B9%2C+50+%CF%87%CF%81%CF%8C%CE%BD%CE%B9%CE%B1+%CE%BC%CE%B5%CF%84%CE%AC.mp4" ></video-screen>
            <div class="seats position-relative"  style="height:100px;z-index:1;">
              <img src="@/assets/images/seats.png" style="height:80px;"/>
            </div>

            <div class="companies my-4 ">
              <h2 class="text-white">
                Εταιρείες & Προϊόντα
              </h2>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="tv-floor">

    </div>

    <div class="motoroil-group-logos d-block">
      <div class="general-container">
        <div v-for="company in companies" :key="company" class="d-inline-block logo" :class="selectedCompany && company === selectedCompany.id ? 'current-logo' : ''" >
          <img :src="getLogo(company)" @click="changeCompany(company)"/>
        </div>
      </div>

    </div>


    <div id="content" v-if="selectedCompany" class="motoroil-company-content">
      <div class="general-container">

        <div class="company-content">
          <img src="@/assets/images/spiral.png" width="100%" class="spiral"/>
          <div class="row company-row-container">

            <div class="col-12 col-md-3">
              <img :src="selectedCompany.logo" class="selected-company-logo"/>

              <div class="navigation-card">

                <div  v-for="section of sections" :key="section.hash" class="navigation-card-item">
                  <a v-if="hasProperty(section)" :href="$route.path + '#' + section.hash">
                    <img src="@/assets/icons/check.png" width="20"/> {{section.name}}
                  </a>

                </div>
              </div>
            </div>

            <div class="col-12 justify-paragaraphs-totally col-md-9 pr-md-5">
              <img :src="selectedCompany.headerImg"/>

              <div v-if="selectedCompany.profile" id="profile" class="content-section mt-5 mb-5 text-left">
                <h2 class="text-primary mb-4">Profile</h2>

                <div v-html="selectedCompany.profile"/>
              </div>

              <div v-if="selectedCompany.products" id="products" class="content-section mt-5 mb-5 text-left">
                <h2 class="text-primary mb-4">Προϊόντα & Υπηρεσίες</h2>

                <div v-html="selectedCompany.products"/>

                <img v-if="selectedCompany.extraImg" :src="selectedCompany.extraImg" class="mt-3"/>
              </div>

              <div v-if="selectedCompany.hr" id="hr" class="content-section mt-5 mb-5 text-left">
                <h2 class="text-primary mb-4">Ανθρώπινο Δυναμικό</h2>

                <div v-html="selectedCompany.hr"/>
              </div>

              <div v-if="selectedCompany.vision" id="vision" class="content-section mt-5 mb-5 text-left">
                <h2 class="text-primary mb-4">Όραμα</h2>

                <div v-html="selectedCompany.vision"/>
              </div>
              <div v-if="selectedCompany.organogramma" id="organogramma" class="content-section mt-5 mb-5 pb-5 text-left">
                <h2 class="text-primary mb-4">Οργανόγραμμα</h2>

                <b-button variant="red" class="cta-button" 
                @click="redirectTo('https://motoroil.sharepoint.com/sites/el-HR/Shared%20Documents/%CE%94%CE%97%CE%9C%CE%9F%CE%A3%CE%99%CE%91%20%CE%91%CE%A1%CE%A7%CE%95%CE%99%CE%91%20-%20PUBLIC%20FILES/%CE%9F%CF%81%CE%B3%CE%B1%CE%BD%CE%BF%CE%B3%CF%81%CE%AC%CE%BC%CE%BC%CE%B1%CF%84%CE%B1%20-%20Org%20Charts/?csf=1&web=1&e=NLzxnB/')">
                Δείτε εδώ</b-button>
                <!-- <div class="position-relative">
                  <img :src="selectedCompany.organogramma" class="cursor-pointer" @click="openLightbox([selectedCompany.organogramma])" loading="lazy"/>

                  <div class="position-absolute enlarge-image-box cursor-pointer" style=""   @click="openLightbox([selectedCompany.organogramma])"  >
                    <img src="@/assets/enlarge-image.svg">
                  </div>
                </div>

                <div v-if="selectedCompany.organogramma2" class="position-relative">
                  <img  :src="selectedCompany.organogramma2" class="cursor-pointer mt-3" @click="openLightbox([selectedCompany.organogramma2])" loading="lazy"/>


                  <div class="position-absolute enlarge-image-box cursor-pointer" style=""   @click="openLightbox([selectedCompany.organogramma2])"  >
                    <img src="@/assets/enlarge-image.svg">
                  </div>
                </div> -->



              </div>
              <div class="row paginate-groups mt-5 mb-5">
                <div class="col-6">
                  <div v-if="selectedCompany.prev" class="d-flex align-items-center cursor-pointer" @click="changeCompany(selectedCompany.prev)">

                    <img src="@/assets/icons/proigoumeno.svg" class="shadow-none" width="25">
                    <p class="ml-2 p-0 mb-0 text-zonapro font-weight-bold" style="color:#002957">{{companyDetails[selectedCompany.prev].name}}</p>
                  </div>

                </div>
                <div class="col-6 text-right pl-2" >
                  <div v-if="selectedCompany.next" class="d-flex align-items-center justify-content-end cursor-pointer" @click="changeCompany(selectedCompany.next)">
                    <p class="mr-2 p-0 mb-0 text-zonapro font-weight-bold" style="color:#002957">{{companyDetails[selectedCompany.next].name}}</p>
                    <img src="@/assets/icons/epomeno.svg" class="shadow-none" width="25">

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="next-section">
          <next-section-btn text="Office Tour" to-url="office-tour"></next-section-btn>
        </div>
      </div>


  </div>

    <div class="home-container">
      <div v-if="selectedCompany" class="etairies-left-img"><img src="@/assets/structure-images/etairies-bottom-left.png"/></div>
      <div v-if="selectedCompany" class="etairies-right-img"><img :src="bottomItems[getCompanyId]"/></div>
    </div>


  </div>

</template>

<style lang="scss">
#motoroil-group{
  background: linear-gradient(0.43deg, #475580 1.58%, #170F20 99.63%);
  h2{
    font-family: "ZonaPro", sans-serif;
    font-weight:bold!important;
  }
  position:relative;
  .tv-floor{
    z-index:0;
    top:650px;
    position:absolute;
    height:40px;
    width:100%;

    background: radial-gradient(40.82% 349.08% at 50.2% 44.72%, #475580 2%, #342B4A 53%, #1e192d 100%);
  }

  .navigation-card{
    background-image: url('~@/assets/content-images/content-card.png');
    background-size:cover;
    background-repeat:no-repeat;
    padding: 60px 10px 60px 30px;
    text-align:left;
    .navigation-card-item{
      a{
        font-family: "ZonaPro", sans-serif;
        font-weight: bold;
      }
      color:$primary;
      font-weight:bold;
      padding:3px 0;
      img{
        max-width:20px;
        margin-right:5px;
      }
    }
  }
  .next-section{
    padding:80px 0;
  }
  .etairies-left-img{
    position:absolute;
    img{
      max-height:350px;
    }
    bottom:0;
    left: 17.5%;
  }
  .etairies-right-img{
    position:absolute;
    img{
      max-height:150px;
    }

    bottom:0;
    right: 10%;
  }
  .content-section{
    color:$primary!important;
  }
  .motoroil-group-first-part{
    min-height:620px;
  }

  .motoroil-group-logos{
    position:sticky;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    top:0;
    z-index:999;
    background:#fff;
    padding:0.75em 0;
    .logo{
      cursor:pointer;
      width:10%;
      img{
        width:80%;
        margin:0 auto;
      }
      &:hover{
        transform:scale(1.05);
      }
      &.current-logo{
      opacity:0.6;
    }
    }
  }
  .screen, .screen img{
    height:500px;
    @media(min-width:1920px) {
      height: 500px;
    }
  }
  .motoroil-company-content{


    padding: 10px;
    .company-content{
      background: linear-gradient(180deg,
          #FFFFFF 0%, #FBFBFB 26%, #F0F0EF 51%, #DEDDDC 76%, #d3d3d3 100%);
      box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.25);
      margin-top:20px;
      .selected-company-logo{
        text-align:left;
        width:200px;
      }
      .spiral{
        margin-top:-15px;
        padding-bottom:20px;
      }
    }
  }
  .company-row-container{
    padding-top:0!important;
    padding-left: 3rem!important;
    padding-right: 3rem!important;
  }
  .justify-paragaraphs-totally{
    p{
      text-align:justify!important;
      max-width:85%;
      a{
        font-weight:bold;
        font-family: inherit;
      }
      *{
        font-family: inherit;
      }
    }
    ul{
      font-size:inherit;
      color:inherit;
      text-align:justify!important;
      max-width:85%;
      li{
        font-size:1rem;
      }
    }
    img{
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }

}
</style>
<script>

import NextSectionBtn from "../components/NextSectionBtn";
import groupContent from "../assets/group-content"
import diilistirio from "../assets/bottom-group/diilistirio.png";
import nrg from "../assets/bottom-group/nrg.png";
import avin from "../assets/bottom-group/avin.png";
import coral from "../assets/bottom-group/coral-gas.png";
import VideoScreen from "../components/VideoScreen";

export default{
  components:{VideoScreen, NextSectionBtn},

  computed:{
    getCompanyId(){
      return this.companies.findIndex(x => x === this.selectedCompany.id)
    }
  },
  data(){
    return{
      companies: ['motoroil', 'avin', 'coral', 'coral-gas', 'lpc', 'nrg', 'shell', 'more', 'ci', 'bfs'],
      sections: [
        {
          name: 'Profile',
          hash: 'profile'
        },
        {
          name: 'Προϊόντα & Υπηρεσίες',
          hash: 'products'
        },
        {
          name: 'Ανθρώπινο Δυναμικό',
          hash: 'hr'
        },
        {
          name: 'Όραμα',
          hash: 'vision'
        },
        {
          name: 'Οργανόγραμμα',
          hash: 'organogramma'
        }
      ],
      companyDetails:groupContent,
      selectedCompany:  null,
      bottomItems: [diilistirio, avin, coral, coral, diilistirio, nrg, diilistirio]
    }
  },

  mounted(){
    this.selectedCompany = this.companyDetails['motoroil'];
  },
  methods:{
    changeCompany(company){
      this.selectedCompany = this.companyDetails[company];
      window.scrollTo(0, document.getElementById('content').offsetTop);

    },
    getLogo(logo) {
      return this.companyDetails[logo] ? this.companyDetails[logo].logo : '';
    },
    hasProperty(section){
      return this.selectedCompany[section.hash]
    },
  }
}
</script>
