<template>

  <div id="my-tools">

    <div class="container h-100 position-relative mb-5">
      <div class="circles" v-html="svgIcons.circles"/>
      <div class="row align-items-center pt-5">

        <div class="col-12">

          <h2 class="text-blue title">My Tools</h2>
          <h5 class="text-white mt-4">Essentials & More...</h5>

        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-12 p-4">
          <div class="inner-content " >


            <div class="inner-content-shadow">
              <div class="row pt-5 pb-5">
                <div class="col-12 col-md-6">
                  <img src="@/assets/tools/developer-bigger.png" style="max-width:80%"/>
                </div>
                <div class="col-12 col-md-6">
                  <h3 class="inner-content-title text-left text-zonapro">
                    {{  myTools.itHelpdesk.title }}
                  </h3>


                  <div class="content mt-4">
                    <div v-html="myTools.itHelpdesk.content"/>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12 col-md-6">
                  <img src="@/assets/tools/snow.png" style="max-width:80%"/>
                  <b-button variant="red" class="cta-button" @click="redirectTo('https://mohprod.service-now.com/sp')">Go to Snow</b-button>
                </div>
                <div class="col-12 col-md-6">

                  <div class="content">
                    <div v-html="myTools.snow.content"/>
                  </div>
                </div>
              </div>
              <img class="gear-big animate__animated animate__fadeInTopLeft" src="@/assets/images/gear-big.png"/>
              <img class="gear-small animate__animated animate__fadeInLeft" src="@/assets/images/gear-small.png"/>
              <img class="kleidi1 animate__animated animate__fadeInBottomRight" src="@/assets/tools/kleidi-1.png"/>
              <img class="kleidi2 animate__animated animate__fadeInRight" src="@/assets/tools/kleidi-2.png"/>
              <img src="@/assets/tools/aspida.png" class="aspida animate__animated animate__fadeInLeft"/>
            </div>



          </div>

        </div>
<!--        <div class="col-12 p-4">-->
<!--          <div class="inner-content text-center" >-->

<!--              <div class="row">-->
<!--                <div class="col-12 col-md-9">-->
<!--                  <div class="inner-content-shadow position-relative" >-->
<!--                    <h3 class="inner-content-title text-zonapro text-left">-->
<!--                      {{  myTools.meetings.title }}-->
<!--                    </h3>-->

<!--                    <div class="content">-->
<!--                      <div v-html="myTools.meetings.content"/>-->
<!--                      <div v-for="item in myTools.meetings.items" :key="item.email">-->
<!--                        <h3 class="inner-content-section-title w-50" >-->
<!--                          {{ item.company }}-->
<!--                        </h3>-->
<!--                        <p class="text-left">-->
<!--                          Επικοινωνία: <strong>{{ item.name }}</strong><br>-->
<!--                          <span v-if="item.phone">Τηλ: <strong>{{ item.phone }}</strong><br></span>-->
<!--                          <span v-if="item.email">Email: <strong>{{item.email}}</strong></span>-->
<!--                        </p>-->
<!--                      </div>-->
<!--                    </div>-->




<!--                    <img src="@/assets/tools/calendar.png" class="calendar"/>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-12 col-md-3">-->
<!--                  <img src="@/assets/tools/meeting-room-tools.png" class="gynaika"/>-->
<!--                </div>-->
<!--              </div>-->


<!--          </div>-->

<!--        </div>-->
        <div class="col-12 p-4">
          <div class="inner-content text-center" >

            <div class="row">
              <div class="col-12 col-md-3">
                <img src="@/assets/tools/car.png" class="car"/>
              </div>
              <div class="col-12 col-md-9">
                <div class="inner-content-shadow position-relative"  >
                  <h3 class="inner-content-title ml-5 text-zonapro text-left">
                    {{  myTools.cars.title }}
                  </h3>

                  <div class="content ml-5" style="max-width:500px;">
                    <div v-html="myTools.cars.content"/>
                  </div>


                </div>
              </div>

            </div>


          </div>

        </div>

        <div class="col-12 p-4">
          <div class="inner-content text-center" >
            <div class="inner-content-shadow" >
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="w-75">
                    <h3 class="inner-content-title text-zonapro text-left">
                      {{ myTools.phones.title }}
                    </h3>

                    <div class="content mt-4" v-html="myTools.phones.content">

                    </div>
                  </div>


                </div>
                <div class="col-12 col-md-6">

                  <div class="item">
                    <h3 class="inner-content-section-title mt-0">
                      MoH
                    </h3>
                    <p class="text-left">
                      Επικοινωνία: <strong>Σωκρατίδης Αναστάσιος</strong><br>
                      Τηλ: <strong>210 80940213</strong><br>
                      Email: <strong>sokratan@moh.gr</strong>
                    </p>
                  </div>
                  <div class="item mt-4">
                    <h3 class="inner-content-section-title mt-0">
                      Coral, Coral Innovations
                    </h3>
                    <p class="text-left">
                      Επικοινωνία: <strong>Γιδίτση Γεωργία</strong><br>
                      Τηλ: <strong>210 9476 268</strong><br>
                      Email: <strong>georgia.giditsi@ceg.gr</strong>
                    </p>
                  </div>
                  <div class="item mt-4">
                    <h3 class="inner-content-section-title mt-0">
                      Avin, Lpc & Cytop
                    </h3>
                    <p class="text-left">
                      Επικοινωνία: <strong>Μίαρης Αλέκος</strong><br>
                      Τηλ: <strong>210 8093582</strong><br>
                      Email: <strong>miari.al@avinoil.gr</strong>
                    </p>
                  </div>
                  <div class="item mt-4">
                    <h3 class="inner-content-section-title mt-0">
                      Coral Gas, <br>
                      Shell & Moh Aviation
                    </h3>
                    <p class="text-left">
                      Επικοινωνία: <strong>Χαβιάρης Νάσος</strong><br>
                      Τηλ: <strong>2108094283</strong><br>
                      Email: <strong>nasos.haviaris@ceg.gr</strong>
                    </p>
                  </div>
                  <div class="item mt-4">
                    <h3 class="inner-content-section-title mt-0">
                      nrg
                    </h3>
                    <p class="text-left">
                      Επικοινωνία: <strong>Παπαγιαννοπούλου Γεωργία</strong><br>
                      Τηλ: <strong>2109606091 (ext. 450)</strong><br>
                      Email: <strong>gpapagiannopoulou@nrg.gr</strong>
                    </p>
                  </div>



                </div>
              </div>

            </div>


            <img src="@/assets/tools/mobile.png" class="phone animate__animated animate__fadeInRight"/>
            <img src="@/assets/tools/mobile-woman.png" class="phone-woman"/>

          </div>



        </div>
        <div class="col-12 p-4">
          <div class="inner-content text-center" >
            <img src="@/assets/tools/portit.png" class="postit"/>
            <div class="inner-content-shadow" style="padding-bottom:100px;">
              <div class="row">
                <div class="col-12">
                  <h3 class="inner-content-title text-zonapro text-left mb-0">
                    Γραφική ύλη
                  </h3>
                </div>
                <div class="col-12 col-md-6 ">
                    <div class="item row align-items-center">
                      <div class="col-2" >
                        <img src="@/assets/tools/step1.png" width="50"/>
                      </div>
                      <div class="col-10">
                        <h3 class="inner-content-section-title">
                          Βήμα 1:
                        </h3>
                        <p class="text-left">
                          Μπαίνουμε στον παρακάτω σύνδεσμο:
                        </p>
                      </div>
                    </div>
                  <div class="row">
                    <div class="offset-md-2 col-md-10">
                      <div class="text-left">
                        <b-button variant="red" class="cta-button" @click="redirectTo('https://www.officemart.gr/')">www.officemart.gr</b-button>
                      </div>
                    </div>
                  </div>



                </div>
                <div class="col-12 col-md-6">

                  <div class="item row align-items-center">
                    <div class="col-2" >
                      <img src="@/assets/tools/step2.png" width="50"/>
                    </div>
                    <div class="col-10">
                      <h3 class="inner-content-section-title">
                        Βήμα 2:
                      </h3>
                      <p class="text-left">
                        Επιλέγουμε τη γραφική ύλη που επιθυμούμε
                      </p>
                    </div>
                  </div>

                  <div class="item mt-4 row align-items-center">
                    <div class="col-2" >
                      <img src="@/assets/tools/step3.png" width="50"/>
                    </div>
                    <div class="col-10">
                      <h3 class="inner-content-section-title">
                        Βήμα 3:
                      </h3>
                      <p class="text-left">
                        Την καταγράφουμε σε ένα excel με κωδικό, περιγραφή και ποσότητα του προϊόντος
                      </p>
                    </div>
                  </div>
                  <div class="item mt-4 row align-items-center">
                    <div class="col-2" >
                      <img src="@/assets/tools/step4.png" width="50"/>
                    </div>
                    <div class="col-10">
                      <h3 class="inner-content-section-title">
                        Βήμα 4:
                      </h3>
                      <p class="text-left">
                        Επικοινωνούμε  με τη γραμματεία της διεύθυνσής μας η οποία θα μας ενημερώσει που θα αποστείλουμε το σχετικό excel
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <img src="@/assets/tools/stationary.png" class="stationary animate__animated animate__fadeInLeft"/>

          </div>



        </div>

      </div>



      <div class="row next-section-wrapper">
        <div class="col-12 col-md-6 text-right">

          <prev-section-btn text="Office Tour" to-url="office-tour"></prev-section-btn>
        </div>
        <div class="col-12 col-md-6 text-left">

          <div class="next-section">

            <next-section-btn text="Go Digital" to-url="go-digital"></next-section-btn>
          </div>
        </div>
      </div>
    </div>
    <img class="sea" src="@/assets/images/sea.png" width="100%" height="60"/>
    <img class="cargoboat" src="@/assets/tools/island.png" width="350"/>
    <img class="ktirio" src="@/assets/images/ktirio.png" width="250"/>
  </div>
</template>

<style lang="scss">
#my-tools{
  p{
    font-size:.9rem!important;
    strong, span{
      font-family: "ZonaPro", sans-serif;
    }
    text-align:left;
  }

  padding-bottom:10rem;
  .postit{
    position:absolute;
    top:-13%;
    left:-8%;
    width:150px;
  }
  .developer{
    position:absolute;
    bottom:0;
    width:350px;
    left:-20%;
  }
  .gear-big{
    position:absolute;
    top:-25px;
    width:50px;
    left:-25px;
  }
  .gear-small{
    position:absolute;
    top:35px;
    left:30px;
    width:30px;
  }
  .aspida {
    position: absolute;
    bottom: -25px;
    left: -20px;
    width:70px;
  }
  .kleidi1{
    position:absolute;
    bottom:-10px;
    width:60px;
    right:10px;
  }
  .snow{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .kleidi2{
    position:absolute;
    bottom:-10px;
    right:-30px;
    width:50px;
  }

  .calendar{
    position:absolute;
    bottom:-40px;
    left: -60px;
    width:120px;
  }
  .gynaika{
    position:absolute;
    bottom:0px;
    left:-50px;
    width:300px;
  }
  .car{
    position:absolute;
    top:120px;
    right:-50px;
    z-index:2;
    width:500px;

  }
  min-height:100vh;
  position:relative;
  background: linear-gradient(180deg,#5ccaec,#c2c7c6 49%,#5ccaec);
  background-blend-mode: multiply;

  .title{
    color:#002d5d;
  }

  .sea{
    z-index:2;
    position:absolute;
    bottom: 0;
    left:0;
    right:0;
    height:100px;
  }
  .cargoboat{
    z-index:3;
    position:absolute;
    left:5%;
    bottom:100px;
  }
  .ktirio{
    z-index: 3;
    position: absolute;
    right: 10%;
    bottom: 100px;
  }
  .inner-content{
    height:100%;
  }
  .inner-content-shadow{
    height:100%;
    background-color: #00000020;
    padding: 3rem 5rem;
    max-width:100%;
    .content{
      p{
        text-align:justify;
        font-size:.9rem!important;
      }
    }
  }
  .inner-content-section-title{
    text-align:left;
    max-width:100%;
  }
  .inner-content-title{
    &.bordered{
      padding-bottom:3rem;
      border-bottom:1px solid #fff;
      margin-bottom:1rem;
    }


  }
  .phone{
    position:absolute;
    right:-5%;
    top:20%;
    width:100px;
  }
  .phone-woman{
    position:absolute;
    bottom:-3%;
    left:10%;
    width:350px;
  }
  .stationary{
    position:absolute;
    bottom:15%;
    left:12%;
    width:250px;
  }

}

</style>
<script>

import PrevSectionBtn from "../components/PrevSectionBtn";
import NextSectionBtn from "../components/NextSectionBtn";
import myTools from "../content/my-tools";
import svgIcons from "../components/svgIcons"
export default{
  components: {PrevSectionBtn, NextSectionBtn},
  data(){
    return{
      myTools,
      svgIcons
    }
  },
  mounted(){
    this.toggleFloatAnimations();
  }
}
</script>