const logo = '<svg width="336" height="83" viewBox="0 0 336 83" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<g clip-path="url(#clip0_2_4)">\n' +
        '<path fill-rule="evenodd" clip-rule="evenodd" d="M41.8232 0.0256626L15.733 2.65176C14.7938 2.747 13.9233 3.18625 13.2899 3.8846C12.6564 4.58294 12.305 5.49079 12.3035 6.43265V37.6806C12.3035 38.6425 12.6865 39.5651 13.3682 40.2452C14.05 40.9254 14.9746 41.3075 15.9387 41.3075H30.4371C31.7561 41.4914 32.9855 42.0788 33.9559 42.9888C34.9264 43.8988 35.5901 45.0866 35.8557 46.3886H35.9844C36.2778 45.0523 36.9858 43.8421 38.0077 42.93C39.0297 42.0179 40.3137 41.4502 41.6774 41.3075H41.8574C42.3243 41.3075 42.7866 41.2156 43.2178 41.0371C43.649 40.8586 44.0407 40.597 44.3704 40.2673C44.7001 39.9375 44.9614 39.5461 45.1393 39.1155C45.3172 38.6848 45.4081 38.2234 45.407 37.7576V3.2591C45.4069 2.80489 45.3114 2.35574 45.1266 1.94062C44.9418 1.5255 44.6719 1.15362 44.3342 0.848972C43.9966 0.54432 43.5987 0.313653 43.1662 0.171847C42.7337 0.0300405 42.2761 -0.0197586 41.8232 0.0256626Z" fill="#8BC650"/>\n' +
        '<path d="M24.6755 17.801C23.0464 17.801 21.3745 15.9533 21.2459 13.9688C21.1558 13.1162 21.3376 12.257 21.7656 11.5136C22.1936 10.7701 22.8459 10.1805 23.6295 9.8286C24.3444 9.55278 25.1274 9.50503 25.8707 9.69195C26.6139 9.87886 27.2808 10.2912 27.7792 10.8722C28.243 11.5624 28.5212 12.3599 28.5873 13.1882C28.6533 14.0165 28.505 14.8479 28.1564 15.6026C27.8443 16.2495 27.3586 16.7974 26.7532 17.1855C26.1478 17.5736 25.4464 17.7866 24.7269 17.801H24.6755ZM24.2296 11.2229C23.7348 11.449 23.3246 11.8259 23.0581 12.2993C22.7916 12.7727 22.6824 13.3183 22.7464 13.8576C22.8407 15.1749 23.9981 16.2869 24.6669 16.2869C25.0833 16.2736 25.4891 16.1533 25.8452 15.9374C26.2012 15.7216 26.4952 15.4177 26.6989 15.0551C26.9444 14.5424 27.0581 13.9768 27.0296 13.4093C27.0012 12.8418 26.8315 12.2903 26.536 11.8046C26.2514 11.4895 25.8794 11.266 25.4672 11.1625C25.055 11.0589 24.6213 11.0799 24.2211 11.2229H24.2296Z" fill="#002957"/>\n' +
        '<path d="M32.4091 17.3818C30.8829 17.3818 29.3139 15.671 29.1767 13.7977C29.092 13.0011 29.263 12.1981 29.6652 11.5048C30.0674 10.8114 30.6799 10.2634 31.4145 9.93981C32.0827 9.67587 32.8171 9.62801 33.514 9.80298C34.2109 9.97796 34.8351 10.3669 35.2985 10.915C35.7325 11.5583 35.9936 12.302 36.0567 13.075C36.1197 13.8479 35.9826 14.624 35.6585 15.3289C35.3623 15.9337 34.9047 16.4453 34.3359 16.8075C33.7672 17.1697 33.1094 17.3684 32.4348 17.3818H32.4091ZM31.9975 11.24C31.536 11.4442 31.1517 11.7898 30.9004 12.2266C30.6491 12.6635 30.5439 13.1688 30.6 13.6694C30.6943 14.9012 31.7746 15.9362 32.4091 15.9362C32.7982 15.9238 33.1776 15.8119 33.5109 15.6112C33.8442 15.4105 34.1202 15.1277 34.3124 14.79C34.5435 14.3114 34.6505 13.7825 34.6235 13.252C34.5966 12.7214 34.4365 12.2061 34.1581 11.7533C33.8903 11.4598 33.5413 11.2521 33.1553 11.1562C32.7693 11.0603 32.3635 11.0805 31.989 11.2144L31.9975 11.24Z" fill="#002957"/>\n' +
        '<path d="M27.3677 32.6594C27.1743 32.655 26.9873 32.5898 26.8333 32.4731C26.6794 32.3563 26.5663 32.194 26.5103 32.0093L24.1697 24.7897C24.1325 24.6728 24.1188 24.5498 24.1293 24.4277C24.1398 24.3056 24.1742 24.1867 24.2307 24.0779C24.2873 23.9691 24.3647 23.8724 24.4587 23.7935C24.5527 23.7145 24.6613 23.6548 24.7784 23.6177C24.8955 23.5807 25.0187 23.567 25.1411 23.5774C25.2635 23.5879 25.3827 23.6223 25.4917 23.6787C25.6008 23.7351 25.6977 23.8124 25.7768 23.9061C25.856 23.9999 25.9158 24.1083 25.953 24.2251L28.2593 31.4875C28.2966 31.6043 28.3105 31.7273 28.3001 31.8495C28.2896 31.9716 28.2551 32.0905 28.1986 32.1994C28.142 32.3082 28.0646 32.4049 27.9705 32.4838C27.8765 32.5628 27.7678 32.6224 27.6506 32.6594C27.557 32.675 27.4613 32.675 27.3677 32.6594Z" fill="#002957"/>\n' +
        '<path d="M23.0379 32.9502C22.961 32.9585 22.8833 32.9585 22.8064 32.9502C22.6879 32.9203 22.5765 32.8673 22.4787 32.7943C22.3809 32.7212 22.2985 32.6296 22.2364 32.5246C22.1742 32.4196 22.1335 32.3034 22.1165 32.1827C22.0996 32.0619 22.1067 31.9391 22.1376 31.8211L24.0239 24.4218V17.878C24.0239 17.6307 24.1224 17.3935 24.2976 17.2187C24.4729 17.0438 24.7106 16.9456 24.9584 16.9456C25.2063 16.9456 25.444 17.0438 25.6193 17.2187C25.7945 17.3935 25.893 17.6307 25.893 17.878V24.4988C25.9066 24.5752 25.9066 24.6534 25.893 24.7298L23.981 32.2402C23.9284 32.4481 23.8062 32.6317 23.6347 32.7608C23.4632 32.8899 23.2527 32.9568 23.0379 32.9502Z" fill="#002957"/>\n' +
        '<path d="M34.7583 32.6594C34.5642 32.6532 34.377 32.5862 34.2232 32.468C34.0693 32.3498 33.9566 32.1863 33.901 32.0007L31.6803 24.7897C31.6448 24.6723 31.6329 24.5491 31.6452 24.4271C31.6575 24.3051 31.6937 24.1867 31.7518 24.0787C31.8099 23.9706 31.8888 23.8751 31.9839 23.7975C32.0791 23.7199 32.1886 23.6617 32.3062 23.6263C32.4239 23.5909 32.5474 23.579 32.6697 23.5912C32.7919 23.6035 32.9106 23.6396 33.0189 23.6976C33.1271 23.7556 33.2229 23.8343 33.3007 23.9292C33.3785 24.0241 33.4368 24.1334 33.4723 24.2508L35.65 31.4789C35.6856 31.5963 35.6977 31.7196 35.6855 31.8416C35.6733 31.9637 35.6372 32.0821 35.579 32.1902C35.5209 32.2982 35.442 32.3938 35.3467 32.4714C35.2515 32.549 35.1419 32.607 35.0241 32.6423C34.9368 32.6605 34.8473 32.6663 34.7583 32.6594Z" fill="#002957"/>\n' +
        '<path d="M30.66 32.9502C30.5831 32.9585 30.5055 32.9585 30.4286 32.9502C30.1889 32.8897 29.9831 32.7368 29.8562 32.5252C29.7292 32.3136 29.6915 32.0604 29.7512 31.8211L31.6374 24.4218V17.878C31.6374 17.6307 31.7359 17.3935 31.9112 17.2187C32.0864 17.0438 32.3242 16.9456 32.572 16.9456C32.8199 16.9456 33.0576 17.0438 33.2328 17.2187C33.4081 17.3935 33.5065 17.6307 33.5065 17.878V24.4988C33.5159 24.5755 33.5159 24.6531 33.5065 24.7298L31.586 32.2402C31.5354 32.4459 31.4161 32.6283 31.2478 32.7573C31.0795 32.8863 30.8722 32.9544 30.66 32.9502Z" fill="#002957"/>\n' +
        '<path d="M21.6918 21.7872C21.5803 21.7886 21.4697 21.7682 21.366 21.7273L18.6395 20.735C18.4067 20.6519 18.2164 20.48 18.1104 20.2572C18.0043 20.0343 17.9911 19.7786 18.0736 19.546C18.1588 19.3131 18.3327 19.1232 18.5575 19.0175C18.7822 18.9118 19.0397 18.8988 19.274 18.9815L21.3317 19.7342C21.5204 19.4776 21.7604 19.1697 22.0262 18.8788C23.0293 17.7411 23.9296 17.168 24.787 17.168C27.3591 17.0996 33.8495 16.6719 33.9181 16.6719C34.0452 16.6546 34.1745 16.6636 34.298 16.6984C34.4215 16.7331 34.5366 16.7929 34.6359 16.8739C34.7352 16.955 34.8167 17.0556 34.8754 17.1694C34.9341 17.2833 34.9686 17.4079 34.9769 17.5357C34.9851 17.6634 34.9669 17.7915 34.9234 17.9119C34.8799 18.0324 34.812 18.1426 34.7239 18.2357C34.6358 18.3288 34.5295 18.4028 34.4115 18.4531C34.2936 18.5034 34.1664 18.5289 34.0381 18.5281C33.7723 18.5281 27.4534 18.9558 24.8384 19.0328C24.3754 19.0328 23.2522 20.1705 22.4806 21.3937C22.3907 21.5179 22.272 21.6186 22.1346 21.6871C21.9973 21.7556 21.8454 21.79 21.6918 21.7872Z" fill="#002957"/>\n' +
        '<path d="M37.6734 24.9522C37.5557 24.5912 37.3262 24.2767 37.0182 24.0538C36.7101 23.831 36.3392 23.7113 35.9587 23.7118H35.9072C35.5264 23.71 35.1548 23.8292 34.8465 24.0522C34.5382 24.2752 34.3091 24.5905 34.1925 24.9522H32.1176V29.5628H39.7311V24.9522H37.6734ZM35.9158 24.0968H35.9672C36.2397 24.0985 36.5057 24.1806 36.7315 24.3328C36.9573 24.485 37.1329 24.7006 37.2362 24.9522H34.664C34.7696 24.707 34.9439 24.4975 35.166 24.3488C35.388 24.2001 35.6484 24.1185 35.9158 24.1139V24.0968Z" fill="#D91F3B"/>\n' +
        '<path d="M35.9929 24.6955C35.7721 24.6963 35.5583 24.6182 35.3901 24.4755C35.2219 24.3327 35.1103 24.1347 35.0755 23.9171C34.889 22.9157 34.6194 21.9315 34.2696 20.9745C33.7209 19.5289 33.0521 18.6393 32.4691 18.5623C31.8595 19.3397 31.416 20.2334 31.1659 21.1884C30.7115 22.4715 30.4028 23.6947 30.4028 23.7033C30.3796 23.8286 30.331 23.9479 30.2598 24.0538C30.1887 24.1596 30.0965 24.2498 29.989 24.3187C29.8815 24.3876 29.7611 24.4338 29.635 24.4545C29.5089 24.4751 29.38 24.4697 29.2561 24.4387C29.1322 24.4076 29.0159 24.3516 28.9146 24.2739C28.8133 24.1963 28.729 24.0988 28.6669 23.9874C28.6048 23.876 28.5663 23.7531 28.5537 23.6262C28.5411 23.4994 28.5547 23.3713 28.5937 23.2499C28.8484 22.2333 29.1576 21.2311 29.5197 20.2474C30.4285 17.7839 31.2345 16.7232 32.3148 16.6975C33.8495 16.6975 35.0927 17.878 36.0272 20.3415C36.4031 21.4052 36.6899 22.4982 36.8846 23.6092C36.9221 23.8537 36.8624 24.1032 36.7182 24.3045C36.5741 24.5058 36.357 24.643 36.113 24.687L35.9929 24.6955Z" fill="#002957"/>\n' +
        '<path d="M23.5351 46.3031V70.1261H21.5803V51.0164L12.2348 65.6866H11.2917L1.9891 51.1447V70.1261H1.52588e-05V46.3031H1.1403L11.7976 63.0605L22.4206 46.3031H23.5351Z" fill="#002957"/>\n' +
        '<path d="M44.8498 52.06L36.8504 70.9302C34.8612 75.7119 32.2977 76.4476 29.9056 76.4476C29.035 76.4188 28.1711 76.2838 27.3334 76.0455V74.2321C28.1933 74.5182 29.0934 74.6655 29.9999 74.6683C32.4263 74.6683 33.7809 73.4195 35.0241 70.665L26.4503 52.06H28.508L35.993 68.5094L42.852 52.06H44.8498Z" fill="#002957"/>\n' +
        '<path d="M81.5372 46.1748L72.5346 70.1262H69.1565L63.292 57.5089L57.5304 70.1262H54.1952L45.1155 46.1748H52.772L56.5444 57.9537L61.603 46.1748H65.1097L70.1683 58.0564L74.0179 46.1748H81.5372Z" fill="#002957"/>\n' +
        '<path d="M99.0363 62.7269H86.4585C86.9301 64.5403 88.1133 65.4471 90.2996 65.4471C91.1479 65.4599 91.9879 65.2784 92.7548 64.9165C93.5218 64.5547 94.1954 64.0221 94.7237 63.3599L98.8049 66.696C97.1501 69.0483 94.3465 70.5966 89.6651 70.5966C83.3205 70.5966 79.7795 66.7986 79.7795 60.9049C79.7795 55.0111 83.895 51.1875 89.5623 51.1875C95.8983 51.1875 99.0707 55.225 99.0707 60.9049C99.0707 61.3411 99.0706 62.2222 99.0363 62.7269ZM86.3899 59.1256H92.6317C92.6277 58.7336 92.5441 58.3465 92.3859 57.9877C92.2276 57.6289 91.9982 57.3058 91.7113 57.0379C91.4244 56.7701 91.086 56.563 90.7166 56.4293C90.3473 56.2956 89.9546 56.238 89.5623 56.26C88.2505 56.26 86.8272 56.9016 86.3899 59.1256Z" fill="#002957"/>\n' +
        '<path d="M101.497 44.5239H108.245V70.186H101.497V44.5239Z" fill="#002957"/>\n' +
        '<path d="M110.877 60.9049C110.877 55.3875 114.418 51.2473 120.822 51.2473C124.869 51.2473 127.767 52.8298 129.353 55.225L124.698 59.0914C124.372 58.4693 123.878 57.9506 123.272 57.5938C122.666 57.2371 121.972 57.0566 121.268 57.0727C120.779 57.0572 120.292 57.1442 119.839 57.3279C119.385 57.5116 118.975 57.788 118.635 58.1393C118.295 58.4906 118.033 58.909 117.864 59.3677C117.696 59.8263 117.626 60.315 117.659 60.8022C117.659 63.2316 119.142 64.7456 121.371 64.7456C122.079 64.7495 122.775 64.5683 123.39 64.2201C124.005 63.8718 124.518 63.3688 124.878 62.7611L129.431 66.6275C127.776 68.9799 124.947 70.5624 120.994 70.5624C114.512 70.5624 110.877 66.7644 110.877 60.9049Z" fill="#002957"/>\n' +
        '<path d="M130.468 60.9049C130.468 54.917 134.515 51.1875 140.757 51.1875C146.998 51.1875 151.045 54.917 151.045 60.9049C151.045 66.8927 146.998 70.6308 140.757 70.6308C134.515 70.6308 130.468 66.9269 130.468 60.9049ZM144.186 60.9049C144.186 58.5525 142.9 57.0384 140.808 57.0384C138.716 57.0384 137.439 58.5525 137.439 60.9049C137.439 63.2572 138.75 64.7799 140.808 64.7799C142.866 64.7799 144.126 63.2829 144.126 60.9049H144.186Z" fill="#002957"/>\n' +
        '<path d="M183.917 58.8861V70.1262H177.135V60.2035C177.135 58.0478 176.767 56.8674 174.88 56.8674C174.36 56.8384 173.841 56.9445 173.373 57.1754C172.906 57.4063 172.507 57.754 172.214 58.1847V70.1604H165.561V60.2377C165.561 58.082 165.021 56.9016 163.169 56.9016C162.675 56.8916 162.186 56.9961 161.739 57.2069C161.293 57.4176 160.902 57.729 160.597 58.1163V70.1262H153.815V51.658H160.262V52.9667C161.902 51.741 163.907 51.1022 165.955 51.1533C168.416 51.1533 170.002 51.8547 170.979 53.3003C172.801 51.8852 175.05 51.1283 177.358 51.1533C182.331 51.1533 183.917 53.5741 183.917 58.8861Z" fill="#002957"/>\n' +
        '<path d="M205.635 62.7269H193.057C193.529 64.5403 194.703 65.4471 196.898 65.4471C197.745 65.4598 198.584 65.2783 199.349 64.9164C200.115 64.5545 200.787 64.022 201.314 63.3599L205.395 66.696C203.74 69.0483 200.945 70.5966 196.255 70.5966C189.919 70.5966 186.378 66.7986 186.378 60.9049C186.378 55.0111 190.493 51.1875 196.152 51.1875C202.497 51.1875 205.669 55.225 205.669 60.9049C205.669 61.3411 205.669 62.2222 205.635 62.7269ZM192.997 59.1256H199.23C199.227 58.7333 199.145 58.3456 198.987 57.9862C198.829 57.6268 198.6 57.3032 198.312 57.0351C198.025 56.767 197.686 56.56 197.316 56.4268C196.947 56.2935 196.553 56.2368 196.161 56.26C194.84 56.26 193.426 56.9016 192.997 59.1256Z" fill="#002957"/>\n' +
        '<path d="M224.214 66.7045H215.375L213.823 70.1262H206.372L217.467 46.1748H222.148L233.294 70.1262H225.809L224.214 66.7045ZM217.355 61.4523H222.32L221.308 58.8861L219.85 54.3781L218.367 58.8861L217.355 61.4523Z" fill="#002957"/>\n' +
        '<path d="M241.448 68.749V70.1262H235.009V44.464H241.654V52.77C242.972 51.6075 244.688 50.995 246.446 51.0592C251.436 51.0592 254.806 54.8914 254.806 60.845C254.806 66.7986 251.471 70.5368 246.232 70.5368C245.351 70.6137 244.465 70.4931 243.637 70.1838C242.809 69.8744 242.061 69.3842 241.448 68.749ZM247.818 60.9049C247.818 58.1847 246.438 56.8332 244.389 56.8332C243.781 56.8007 243.177 56.9452 242.651 57.2489C242.124 57.5527 241.697 58.0026 241.422 58.544V63.1546C241.652 63.7065 242.047 64.1739 242.553 64.4925C243.06 64.8112 243.653 64.9653 244.251 64.9338C246.566 64.9424 247.818 63.3941 247.818 60.9049Z" fill="#002957"/>\n' +
        '<path d="M256.615 60.9049C256.615 54.917 260.662 51.1875 266.903 51.1875C273.145 51.1875 277.192 54.917 277.192 60.9049C277.192 66.8927 273.145 70.6308 266.903 70.6308C260.662 70.6308 256.615 66.9269 256.615 60.9049ZM270.333 60.9049C270.333 58.5525 269.056 57.0384 266.963 57.0384C264.871 57.0384 263.594 58.5525 263.594 60.9049C263.594 63.2572 264.906 64.7799 266.963 64.7799C269.021 64.7799 270.307 63.2829 270.307 60.9049H270.333Z" fill="#002957"/>\n' +
        '<path d="M279.113 60.9391C279.113 54.9513 282.482 51.1533 287.506 51.1533C289.254 51.0909 290.959 51.7037 292.265 52.8641V51.658H298.944V70.1261H292.453V68.7147C291.279 70.2288 289.255 70.5966 287.772 70.5966C282.448 70.6308 279.113 66.7986 279.113 60.9391ZM292.428 63.1631V58.5525C292.16 58.0054 291.734 57.5504 291.206 57.2457C290.678 56.9409 290.07 56.8003 289.461 56.8417C287.472 56.8417 286.032 58.1932 286.032 60.9134C286.032 63.4027 287.318 64.9509 289.573 64.9509C290.181 64.9995 290.79 64.852 291.308 64.5303C291.827 64.2085 292.228 63.7293 292.453 63.1631H292.428Z" fill="#002957"/>\n' +
        '<path d="M302.416 51.6579H308.855V53.6767C309.548 52.8946 310.399 52.2682 311.352 51.8391C312.306 51.41 313.339 51.1878 314.385 51.1875H314.788V58.1505C314.222 58.0497 313.649 57.9926 313.074 57.9794C310.81 57.9794 309.224 58.9203 309.224 61.7774V70.1261H302.451L302.416 51.6579Z" fill="#002957"/>\n' +
        '<path d="M316.169 60.9391C316.169 54.9513 319.547 51.1533 324.571 51.1533C326.316 51.0904 328.018 51.7035 329.321 52.8641V44.5239H336V70.186H329.492V68.7746C328.309 70.2887 326.286 70.6565 324.803 70.6565C319.512 70.6309 316.169 66.7986 316.169 60.9391ZM329.492 63.1632V58.5525C329.224 58.0061 328.798 57.5518 328.27 57.2472C327.742 56.9426 327.135 56.8015 326.526 56.8417C324.537 56.8417 323.096 58.1933 323.096 60.9134C323.096 63.4027 324.374 64.951 326.637 64.951C327.242 64.9938 327.844 64.8437 328.358 64.5223C328.871 64.2009 329.268 63.7249 329.492 63.1632Z" fill="#002957"/>\n' +
        '<path d="M291.485 81.1609V81.6827H291.099V76.3621H291.485V78.3808C291.669 78.1976 291.889 78.0538 292.13 77.9582C292.372 77.8626 292.631 77.8172 292.891 77.8248C293.214 77.8264 293.531 77.9075 293.815 78.0608C294.099 78.2142 294.341 78.435 294.519 78.7038C294.697 78.9727 294.806 79.2812 294.836 79.602C294.866 79.9228 294.816 80.246 294.691 80.543C294.566 80.84 294.369 81.1016 294.118 81.3046C293.867 81.5077 293.57 81.6459 293.253 81.707C292.936 81.7681 292.609 81.7502 292.3 81.655C291.992 81.5597 291.712 81.39 291.485 81.1609ZM294.383 79.7666C294.399 79.5602 294.37 79.3528 294.3 79.158C294.23 78.9633 294.119 78.7857 293.975 78.6369C293.83 78.4882 293.656 78.3716 293.463 78.295C293.271 78.2184 293.064 78.1835 292.856 78.1926C292.591 78.1916 292.329 78.2521 292.091 78.3694C291.853 78.4867 291.645 78.6575 291.485 78.8684V80.6904C291.639 80.9058 291.844 81.0801 292.082 81.1979C292.319 81.3158 292.583 81.3735 292.848 81.3662C293.058 81.377 293.268 81.3424 293.464 81.2649C293.66 81.1873 293.837 81.0686 293.982 80.9168C294.128 80.765 294.239 80.5837 294.308 80.3852C294.377 80.1868 294.403 79.9757 294.383 79.7666Z" fill="#002957"/>\n' +
        '<path d="M298.875 77.8847L297.161 81.8452C296.74 82.846 296.209 83.0085 295.703 83.0085C295.52 83.0023 295.339 82.9735 295.163 82.923V82.5381C295.342 82.6 295.53 82.6318 295.72 82.6322C296.226 82.6322 296.509 82.367 296.775 81.7768L294.974 77.8676H295.403L296.981 81.2892L298.421 77.8676L298.875 77.8847Z" fill="#002957"/>\n' +
        '<path d="M301.182 82.6407V82.213C301.641 82.4926 302.17 82.6351 302.708 82.6236C303.677 82.6236 304.165 82.1874 304.165 81.3149V80.9898C303.9 81.2603 303.56 81.4462 303.188 81.5241C302.817 81.602 302.431 81.5683 302.079 81.4274C301.726 81.2864 301.424 81.0445 301.209 80.7322C300.995 80.4198 300.878 80.0511 300.873 79.6725C300.862 79.4254 300.903 79.1788 300.993 78.9484C301.083 78.7181 301.221 78.5091 301.397 78.3351C301.573 78.161 301.784 78.0257 302.015 77.9378C302.247 77.8499 302.495 77.8114 302.742 77.8248C303.001 77.8158 303.259 77.8606 303.499 77.9563C303.74 78.052 303.958 78.1966 304.14 78.3808V77.8847H304.525V81.3063C304.525 82.4354 303.951 83.0171 302.708 83.0171C302.175 83.0248 301.65 82.8951 301.182 82.6407ZM304.165 80.5108V78.8684C304.01 78.6541 303.805 78.4806 303.567 78.3629C303.33 78.2452 303.067 78.1867 302.802 78.1926C302.404 78.1926 302.023 78.3503 301.741 78.6311C301.46 78.9118 301.302 79.2926 301.302 79.6896C301.302 80.0866 301.46 80.4674 301.741 80.7481C302.023 81.0288 302.404 81.1865 302.802 81.1865C303.068 81.1954 303.331 81.1383 303.569 81.0204C303.807 80.9024 304.012 80.7273 304.165 80.5108Z" fill="#002957"/>\n' +
        '<path d="M305.666 77.8847H306.052V78.5091C306.176 78.2921 306.358 78.1135 306.577 77.9927C306.796 77.872 307.045 77.8139 307.295 77.8248C307.476 77.8216 307.657 77.8536 307.826 77.9189V78.3552C307.641 78.2674 307.44 78.2207 307.235 78.2183C307.066 78.2232 306.9 78.2629 306.747 78.3349C306.595 78.4068 306.458 78.5094 306.347 78.6363C306.236 78.7632 306.153 78.9116 306.102 79.0723C306.051 79.2329 306.034 79.4023 306.052 79.5698V81.6827H305.666V77.8847Z" fill="#002957"/>\n' +
        '<path d="M308.161 79.7751C308.149 79.3718 308.258 78.9741 308.474 78.633C308.69 78.2918 309.003 78.0227 309.373 77.86C309.743 77.6973 310.153 77.6485 310.551 77.7199C310.949 77.7912 311.317 77.9794 311.607 78.2604C311.897 78.5414 312.097 78.9024 312.18 79.2972C312.264 79.692 312.227 80.1027 312.075 80.4766C311.923 80.8505 311.663 81.1708 311.328 81.3963C310.992 81.6218 310.597 81.7424 310.193 81.7426C309.927 81.7594 309.66 81.7205 309.41 81.6284C309.16 81.5363 308.932 81.393 308.74 81.2077C308.549 81.0225 308.398 80.7993 308.299 80.5526C308.199 80.3058 308.152 80.041 308.161 79.7751ZM311.822 79.7751C311.829 79.4523 311.739 79.1348 311.564 78.863C311.389 78.5913 311.137 78.3777 310.84 78.2494C310.543 78.1211 310.215 78.084 309.897 78.1428C309.578 78.2016 309.285 78.3536 309.054 78.5795C308.822 78.8054 308.664 79.0949 308.598 79.411C308.532 79.7272 308.563 80.0557 308.685 80.3547C308.807 80.6537 309.016 80.9096 309.284 81.0897C309.553 81.2699 309.869 81.3661 310.193 81.3662C310.406 81.3756 310.619 81.3412 310.819 81.265C311.019 81.1888 311.201 81.0725 311.353 80.9234C311.506 80.7742 311.627 80.5953 311.707 80.3978C311.788 80.2002 311.827 79.9883 311.822 79.7751Z" fill="#002957"/>\n' +
        '<path d="M313.031 80.0831V77.8847H313.416V80.0574C313.416 80.9812 313.819 81.3747 314.608 81.3747C314.864 81.3666 315.116 81.3071 315.349 81.1998C315.582 81.0925 315.791 80.9397 315.963 80.7503V77.8847H316.34V81.6827H315.963V81.1694C315.578 81.5272 315.074 81.7312 314.548 81.7426C313.554 81.7426 313.031 81.2464 313.031 80.0831Z" fill="#002957"/>\n' +
        '<path d="M317.446 77.8847H317.832V78.3808C318.016 78.1968 318.235 78.0524 318.477 77.9568C318.719 77.8611 318.978 77.8162 319.238 77.8248C319.561 77.8264 319.879 77.9075 320.163 78.0608C320.447 78.2142 320.688 78.435 320.866 78.7038C321.044 78.9727 321.153 79.2812 321.183 79.602C321.213 79.9228 321.163 80.246 321.038 80.543C320.913 80.84 320.716 81.1016 320.466 81.3046C320.215 81.5077 319.918 81.6459 319.601 81.707C319.284 81.7681 318.956 81.7502 318.648 81.655C318.339 81.5597 318.059 81.39 317.832 81.1609V82.9572H317.446V77.8847ZM320.73 79.7751C320.746 79.5676 320.718 79.3591 320.647 79.1633C320.576 78.9676 320.464 78.789 320.319 78.6394C320.174 78.4899 319.999 78.3727 319.805 78.2957C319.612 78.2187 319.404 78.1835 319.195 78.1926C318.93 78.1867 318.668 78.2452 318.43 78.3629C318.193 78.4806 317.987 78.6541 317.832 78.8684V80.6904C317.985 80.9057 318.188 81.0801 318.424 81.1979C318.661 81.3158 318.923 81.3736 319.187 81.3662C319.398 81.3807 319.611 81.3491 319.809 81.2738C320.007 81.1985 320.187 81.0811 320.335 80.9299C320.483 80.7786 320.597 80.597 320.668 80.3977C320.739 80.1983 320.766 79.9859 320.747 79.7751H320.73Z" fill="#002957"/>\n' +
        '<path d="M323.448 76.6614H324.88V78.723H326.594V76.6614H328.026V81.6827H326.594V79.9719H324.88V81.6827H323.448V76.6614Z" fill="#002957"/>\n' +
        '<path d="M330.787 80.391H330.23V81.6485H328.798V76.6272H330.907C332.365 76.6272 333.153 77.2944 333.153 78.5091C333.173 78.8381 333.097 79.1657 332.934 79.4525C332.771 79.7393 332.529 79.9731 332.236 80.1258L333.368 81.6485H331.653L330.787 80.391ZM330.23 79.2533H330.83C331.439 79.2533 331.687 78.9283 331.687 78.6032C331.687 78.0814 331.482 77.8162 330.83 77.8162H330.23V79.2533Z" fill="#002957"/>\n' +
        '</g>\n' +
    '<defs>\n' +
        '<clipPath id="clip0_2_4">\n' +
            '<rect width="336" height="83" fill="white"/>\n' +
            '</clipPath>\n' +
        '</defs>\n' +
    '</svg>\n';

const close = '<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M28 4.87702L23.123 0L14.0004 9.12258L4.87782 0L0 4.87702L9.12258 13.9996L0 23.123L4.87702 28L13.9996 18.8774L23.123 28L28 23.123L18.8774 13.9996L28 4.87702Z" fill="white"/>\n' +
    '</svg>\n'


const hamburger = '<svg width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M22.2386 3.66585H1.83876C1.60306 3.68215 1.3667 3.64689 1.14434 3.56224C0.921981 3.4776 0.718331 3.34538 0.545979 3.17376C0.373627 3.00214 0.236307 2.79477 0.142387 2.56447C0.0484662 2.33417 0 2.08585 0 1.83486C0 1.58387 0.0484662 1.33554 0.142387 1.10525C0.236307 0.874952 0.373627 0.667585 0.545979 0.495965C0.718331 0.324345 0.921981 0.192123 1.14434 0.107481C1.3667 0.0228388 1.60306 -0.0124238 1.83876 0.00387125H22.2386C22.6762 0.0341262 23.0866 0.240191 23.3865 0.580242C23.6863 0.920293 23.8532 1.36885 23.8532 1.83486C23.8532 2.30088 23.6863 2.74943 23.3865 3.08948C23.0866 3.42953 22.6762 3.6356 22.2386 3.66585Z" fill="#192C55"/>\n' +
    '<path d="M22.1265 11.0092H1.7267C1.26875 11.0092 0.829599 10.8642 0.50578 10.6061C0.181961 10.348 0 9.99799 0 9.63301C0 9.26803 0.181961 8.91801 0.50578 8.65993C0.829599 8.40185 1.26875 8.25687 1.7267 8.25687H22.1265C22.5845 8.25687 23.0237 8.40185 23.3475 8.65993C23.6713 8.91801 23.8532 9.26803 23.8532 9.63301C23.8532 9.99799 23.6713 10.348 23.3475 10.6061C23.0237 10.8642 22.5845 11.0092 22.1265 11.0092Z" fill="#192C55"/>\n' +
    '<path d="M22.2386 19.2622H1.83876C1.60306 19.2785 1.3667 19.2432 1.14434 19.1586C0.921981 19.0739 0.718331 18.9417 0.545979 18.7701C0.373627 18.5985 0.236307 18.3911 0.142387 18.1608C0.0484662 17.9305 0 17.6822 0 17.4312C0 17.1802 0.0484662 16.9319 0.142387 16.7016C0.236307 16.4713 0.373627 16.2639 0.545979 16.0923C0.718331 15.9207 0.921981 15.7884 1.14434 15.7038C1.3667 15.6192 1.60306 15.5839 1.83876 15.6002H22.2386C22.6762 15.6304 23.0866 15.8365 23.3865 16.1766C23.6863 16.5166 23.8532 16.9652 23.8532 17.4312C23.8532 17.8972 23.6863 18.3457 23.3865 18.6858C23.0866 19.0258 22.6762 19.2319 22.2386 19.2622Z" fill="#192C55"/>\n' +
    '</svg>\n'


module.exports = {
    logo,
    close,
    hamburger,
    triangle: '<svg width="35" height="21" viewBox="0 0 35 21" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M17.5 21L0.612505 6.33601e-07L34.3875 3.5863e-06L17.5 21Z" fill="white"/>\n' +
        '</svg>\n',
    circles: '<svg width="1425" class="circle-1" height="1425" viewBox="0 0 1425 1425" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<g style="mix-blend-mode:multiply"><circle id="circle1" cx="712.5" cy="712.5" r="712" stroke="#686868"/></g></svg>' +
         '<svg width="1425" class="circle-2" height="1425" viewBox="0 0 1425 1425" fill="none" xmlns="http://www.w3.org/2000/svg"><g style="mix-blend-mode:multiply"><circle id="circle2" cx="712" cy="713" r="554.5" stroke="#686868"/></g></svg>' +
    '<svg width="1425" class="circle-3" height="1425" viewBox="0 0 1425 1425" fill="none" xmlns="http://www.w3.org/2000/svg"><g style="mix-blend-mode:multiply"><circle id="circle2" cx="712" cy="713" r="554.5" stroke="#686868"/></g></svg>'
}