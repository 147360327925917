<template>
  <div id="app">
    <Header/>
    <SideMenu/>
    <div class="inner">
      <router-view v-slot="{ Component, route }">

        <transition name="fade">
          <component :is="Component" :key="route.path"/>
        </transition>


      </router-view>
    </div>


    <image-lightbox v-if="$store.state.showLightbox"></image-lightbox>
  </div>
</template>

<script>
import Header from "./components/Header";
import SideMenu from "./components/SideMenu";
import imageLightbox from "./components/ImageLightbox";
export default {
  name: 'App',
  components: {
    Header,
    SideMenu,
    imageLightbox
  }
}
</script>

<style lang="scss">
@import "./assets/styles/globals/animations.scss";

#app {
  min-height:100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 15s
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0
}
.inner{
  position:relative;
}
</style>
