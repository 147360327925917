<template>

  <div id="sharepoint-go-digital">

    <div class="go-digital-wrapper">
      <div class="circles" v-html="svgIcons.circles"/>
      <div class="container h-100 position-relative mb-5">
        <div class="row align-items-center pt-5">

          <div class="col-12">

            <h2 class="text-blue title">Go Digital</h2>
            <h5 class="text-white mt-4">Τα συστήματά μας</h5>

          </div>
        </div>
      </div>


      <div class="tv-box">

        <img src="@/assets/go-digital/sharepoint/intranet.png" style="width:950px;max-width:90%;margin:0 auto;">
<!--        <video-screen-image-slider :images="images"></video-screen-image-slider>-->


      </div>

      <div class=" central-box">

        <img src="@/assets/go-digital/sharepoint/gd-sharepoint-agenda.png" class="agenda animate__animated animate__fadeInLeft"/>
        <img src="@/assets/go-digital/sharepoint/gd-sharepoint-papers.png" class="papers"/>
        <img src="@/assets/go-digital/sharepoint/gd-sharepoint-pc.png" class="pc"/>

        <div class="central-box-content">

          <h3 class="inner-content-title text-literata text-white mb-5">
            Intranet
          </h3>
          <p class="text-white">
            Μέσω του Intranet του Ομίλου, παρέχεται συστημικά πρόσβαση σε εταιρικές πληροφορίες και εφαρμογές. Εδώ μπορείτε να βρείτε τις ισχύουσες ομιλικές πολιτικές καθώς και χρήσιμα έγγραφα αλλά και πληροφορίες, που αφορούν τις γενικές διευθύνσεις. Επιπλέον, αποτελεί το κύριο μέσο ενημέρωσης σχετικά με τα νέα του Ομίλου, δράσεις και ανακοινώσεις.

          </p>


          <div class="mt-5">
            <b-button variant="red" class="cta-button" @click="redirectTo('https://motoroil.sharepoint.com/sites/el-MotorOil')">Intranet</b-button>
          </div>

        </div>


      </div>

      <div class="row next-section-wrapper ">
        <div class="col-12 text-center">

          <prev-section-btn text="Go Digital" to-url="go-digital"></prev-section-btn>
        </div>
      </div>
    </div>



  </div>

</template>

<style lang="scss">
#sharepoint-go-digital{

  .next-section-wrapper{
    padding-top:5rem;
    padding-bottom:10rem;
  }

  overflow-x:hidden;
  position:relative;
  background: linear-gradient(0deg, #A2D0FF 0%, #6E98FF 66%, #5880FF 100%);

  .go-digital-wrapper{
    position:relative;
    max-width:1440px;
    margin:0 auto;
    .gear-left{
      position:absolute;
      top:25%;
      left: 0;
      width:120px;
    }
    .gear-right{
      position:absolute;
      top:40%;
      right: 0;
      width:70px;
    }
  }

  .central-box{
    background-color: #192C5520;
    position:relative;
    z-index:1;
    padding: 7rem 2rem;
    width:1100px;
    margin:0 auto;


    p{
      max-width:60%;
      margin:0 auto 1.5rem auto;
      text-align:justify;
      font-size:0.9rem;
    }
  }


  .agenda{
    position:absolute;
    bottom: 100px;
    left:-60px;
    width:125px;

  }
  .papers{
    position:absolute;
    top: -25px;
    right:-40px;
    width:250px;
  }
  .pc{
    position:absolute;
    top: -50px;
    left:-110px;
    width:350px;
  }
  .tv-box{
    margin-bottom:7.5rem;
    .tv{
      width:950px;
    }
  }
  .text-blue{
    color:#002d5d;
  }
}
</style>

<script>
import svgIcons from "../../components/svgIcons"
import PrevSectionBtn from "../../components/PrevSectionBtn";
// import VideoScreenImageSlider from "../../components/VideoScreenImageSlider";
export default{

  scrollToTop: true,
  components:{PrevSectionBtn},
  data(){
    return{
      svgIcons,
      images: []
    }
  },
  mounted(){
    for(let i=1;i<=5;i++){
      this.images.push(`/images/intranet/Sharepoint-capture-${i}.jpg`);
    }
    this.toggleFloatAnimations();
  },
  methods:{}
}
</script>