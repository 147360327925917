
<template>
  <div id="video-screen" class="screen mt-3 position-relative">
    <div class="screen-div" style="background-image:url('/images/screen.png')" >
      <video  controls class="position-absolute screen-video">
        <source :src="videoUrl" type="video/mp4">
      </video>
    </div>

  </div>

</template>

<style lang="scss">
#video-screen{
  @media(max-width:900px){
    max-height:350px;
  }
  @media(max-width:500px){
    max-height:200px;
  }

}
#video-screen .video-image{
  object-fit: contain;

}
#video-screen > .screen-div{
  height:500px;
  background-size:contain;
  background-repeat:no-repeat;
  background-position: center;

  position:relative;
  .screen-video{
    //left: 255px;
    left: 20.5%;
    top: 6%;
    width: 59%;
    height:84%;
  }
  @media(max-width:900px){
    max-height:335px;
  }
  @media(max-width:500px){
    max-height:165px;
  }

}
</style>
<script>
export default{

  props:{
    videoUrl: String
  }

}
</script>