<template>

  <div id="basic-policies">

    <div class="basic-policies-wrapper">
      <div class="circles" v-html="svgIcons.circles"/>

      <div class="container h-100 position-relative mb-5">
        <div class="row align-items-center pt-5">

          <div class="col-12">

            <h2 class="text-blue title">Basic Policies</h2>
            <h5 class="text-white mt-4">"You know the drill..."</h5>

          </div>
          <div class="col-12 mt-5">
            <img src="@/assets/basic-policies/policies-main.png" loading="lazy" width="600"/>
          </div>
        </div>
      </div>

      <div class="container position-relative mb-5">

        <div class="row">
          <div class="col-12 col-md-6 my-3">
            <div class="inner-content" >
              <div class="inner-content-shadow">

                <img src="@/assets/basic-policies/policies-tick.png" class="tick animate__animated animate__fadeInTopLeft"/>

                <h3 class="inner-content-title text-left text-zonapro">
                  Πολιτικές Ασφάλειας<br>
                  Προσωπικών Δεδομένων-GDPR
                </h3>


                <div class="content mt-4">
                  <p>
                    Στόχος της Υπηρεσίας Ασφάλειας των πληροφοριακών συστημάτων είναι η διασφάλιση της επιχειρηματικής συνέχειας του Ομίλου
                    Motor Oil καθώς και η ελαχιστοποίηση των κινδύνων στα συστήματα της εταιρίας αποτρέποντας περιστατικά ασφάλειας και περιορίζοντας τις πιθανές επιπτώσεις τους.
                  </p>
                  <p>Στη συγκεκριμένη ενότητα μπορείτε να μάθετε σχετικά με την πολιτική που αφορά:&nbsp; </p><ul><li>Την Προστασία των Προσωπικών Δεδομένων πελατών, συνεργατών και υπαλλήλων της εταιρείας.</li></ul><p>Στη συγκεκριμένη ενότητα μπορείτε να μάθετε σχετικά με τις διαδικασίες που αφορούν:&nbsp; </p><ul><li>Διαχείριση Αλλαγών σε Δραστηριότητες Επεξεργασίας Προσωπικών Δεδομένων</li><li>Διαχωρισμό Εταιρικών – Προσωπικών Δεδομένων</li><li>Διενέργεια Εκτίμησης Αντικτύπου</li></ul>

                  <b-button variant="red" class="cta-button" @click="redirectTo('https://motoroil.sharepoint.com/:u:/r/sites/el-DPO/SitePages/%CE%A0%CE%BF%CE%BB%CE%B9%CF%84%CE%B9%CE%BA%CE%AE-%CE%A0%CF%81%CE%BF%CF%83%CF%84%CE%B1%CF%83%CE%AF%CE%B1%CF%82-%CE%A0%CF%81%CE%BF%CF%83%CF%89%CF%80%CE%B9%CE%BA%CF%8E%CE%BD-%CE%94%CE%B5%CE%B4%CE%BF%CE%BC%CE%AD%CE%BD%CF%89%CE%BD.aspx?csf=1&web=1&e=6WklZ4')">Μάθετε περισσότερα</b-button>
                </div>
              </div>

            </div>

          </div>

          <div class="col-12 col-md-6 my-3">
            <div class="inner-content" >
              <div class="inner-content-shadow">
                <img src="@/assets/basic-policies/policies-shield.png" class="shield animate__animated animate__fadeInBottomRight"/>
                <h3 class="inner-content-title text-left text-zonapro">
                  Πολιτικές Ασφάλειας<br>
                  Πληροφοριακών Συστημάτων
                </h3>


                <div class="content mt-4">
                  <p>
                    Στόχος της Υπηρεσίας Ασφάλειας των πληροφοριακών συστημάτων είναι η διασφάλιση της επιχειρηματικής συνέχειας του Ομίλου Motor Oil καθώς και η
                    ελαχιστοποίηση των κινδύνων στα συστήματα της εταιρίας, αποτρέποντας περιστατικά παραβίασης και περιορίζοντας τις πιθανές επιπτώσεις τους.    </p>
                  <p>Στη συγκεκριμένη ενότητα μπορείτε να μάθετε σχετικά με τις πολιτικές που αφορούν:&nbsp; </p><ul><li>Αποδεκτή Χρήση Εξοπλισμού</li><li>Έλεγχο Πρόσβασης</li><li>Ασφάλεια του Δικτύου</li><li>Εντοπισμό και Απόκριση Περιστατικών Ασφαλείας</li><li>Ασφάλεια στο Cloud</li><li>Ασφάλεια Φορητών Συσκευών</li><li>Διαβάθμιση Πληροφοριών</li></ul><p>Στη συγκεκριμένη ενότητα μπορείτε να μάθετε σχετικά με τις διαδικασίες που αφορούν:&nbsp; </p><ul><li>Αναθεώρηση Πολιτικών</li><li>Αναφορά και Απόκριση Περιστατικών Ασφαλείας</li><li>Απομακρυσμένη Πρόσβαση</li><li>Έλεγχο Ασφάλειας</li></ul>
                  <b-button variant="red" class="cta-button" @click="redirectTo('https://motoroil.sharepoint.com/:u:/r/sites/el-ITSecurity/SitePages/Policies-and-Procedures.aspx?csf=1&web=1&e=k7Vdr2')">Μάθετε περισσότερα</b-button>
                </div>
              </div>

            </div>

          </div>

          <div class="col-12 col-md-9 my-3">
            <div class="inner-content" >
              <div class="inner-content-shadow">
                <img src="@/assets/basic-policies/policies-papers.png" class="papers animate__animated animate__fadeInUp"/>
                <h3 class="inner-content-title text-left text-zonapro">
                  Πολιτικές & Διαδικασίες Ανθρώπινου Δυναμικού
                </h3>


                <div class="content mt-4">
                  <p>Στη συγκεκριμένη ενότητα μπορείτε να μάθετε σχετικά με τις πολιτικές που αφορούν:</p><ul><li>Κώδικας Εταιρικής Συμπεριφοράς</li><li>Κινητά Τηλέφωνα</li><li>Άδειες</li><li>Υγιεινή Ασφάλεια &amp; Περιβάλλον</li><li>Πολιτική Αναφορών</li></ul>
                  <div class="text-left">
                    <b-button variant="red" class="cta-button" @click="redirectTo('https://motoroil.sharepoint.com/sites/el-HR/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=ruNBaT&cid=8da4b3b3%2Df4a3%2D45dc%2D964e%2D651c4eff19d0&RootFolder=%2Fsites%2Fel%2DHR%2FShared%20Documents%2F%CE%94%CE%97%CE%9C%CE%9F%CE%A3%CE%99%CE%91%20%CE%91%CE%A1%CE%A7%CE%95%CE%99%CE%91%20%2D%20PUBLIC%20FILES%2F%CE%9A%CE%BF%CE%B9%CE%BD%CE%AD%CF%82%20%CE%A0%CE%BF%CE%BB%CE%B9%CF%84%CE%B9%CE%BA%CE%AD%CF%82%20%26%20%CE%94%CE%B9%CE%B1%CE%B4%CE%B9%CE%BA%CE%B1%CF%83%CE%AF%CE%B5%CF%82%20%CE%91%CE%BD%CE%B8%CF%81%2E%20%CE%94%CF%85%CE%BD%2E%20%2D%20HR%20Shared%20Policies%20%26%20Procedures%2FMOTOR%20OIL&FolderCTID=0x012000C14245835420AF4FA2848030DA260017')">Μάθετε περισσότερα</b-button>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


      <div class="row next-section-wrapper py-5">
        <div class="col-12 col-md-6 text-right">

          <prev-section-btn text="H&S" to-url="h-s"></prev-section-btn>
        </div>
        <div class="col-12 col-md-6 text-left">

          <div class="next-section">

            <next-section-btn text="My HR" to-url="my-hr"></next-section-btn>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<style lang="scss">
#basic-policies{
  overflow-x:hidden;
  position:relative;
  min-height:100vh;
  background: linear-gradient(0deg, #5880FF 0%, #6E98FF 66%, #A2D0FF 100%);
  .basic-policies-wrapper{
    height:100%;
    position:relative;
    max-width:1440px;
    margin:0 auto;
  }

  .tick{
    position:absolute;
    top:-30px;
    left:-30px;
    width:80px;
  }
  .shield{
    position:absolute;
    bottom:10px;
    right:-35px;
    width:100px;
  }
  .papers{
    position:absolute;
    width:170px;
    right:-100px;
    bottom:50px;
  }


  .inner-content-title{
    font-size:20px;
  }

  .inner-content-shadow{
    padding:3rem 5rem;
    height:100%;
    max-width:100%;
    .content{
      p{
        text-align:left;
        font-size:.9rem!important;
      }
      ul{
        text-align:left;
      }
    }
  }
  .inner-content{
    height:100%;

  }

}
</style>
<script>
import PrevSectionBtn from "../components/PrevSectionBtn";
import NextSectionBtn from "../components/NextSectionBtn";
import svgIcons from "../components/svgIcons"
export default{

  components:{PrevSectionBtn, NextSectionBtn},
  data(){
    return{
      svgIcons
    }
  },
  mounted(){
    this.toggleFloatAnimations();
  }
}
</script>