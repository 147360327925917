
<template>
  <div id="video-screen-no-speakers" class="screen mt-3 position-relative" :class="goals ? 'my-goals' : ''">
    <div class="position-relative mx-auto" style="background-image:url('/images/large-screen.png');" >
      <video height="400" controls class="position-absolute screen-video">
        <source :src="videoUrl" type="video/mp4">
      </video>
    </div>

  </div>

</template>

<style lang="scss">
#video-screen-no-speakers  > div{
  background-size:contain;
  background-repeat:no-repeat;
  background-position: center;
  position:relative;
  height:500px;width:950px;
  .screen-video{
    //left: 255px;
    left: 10.5%;
    top: 5%;
    width: 80%;
    height:88%;
  }
}
#video-screen-no-speakers.my-goals > div{
  height:400px;width:700px;
  max-width:unset;
  background-position: left;
  .screen-video{
    left:3.5%;
    width:87%
  }
}
</style>
<script>
export default{

  props:{
    videoUrl: String,
    goals: {
      type: Boolean,
      default: false
    }
  }

}
</script>