import { render, staticRenderFns } from "./PrevSectionBtn.vue?vue&type=template&id=d96f53ba&scoped=true&"
import script from "./PrevSectionBtn.vue?vue&type=script&lang=js&"
export * from "./PrevSectionBtn.vue?vue&type=script&lang=js&"
import style0 from "./PrevSectionBtn.vue?vue&type=style&index=0&id=d96f53ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d96f53ba",
  null
  
)

export default component.exports