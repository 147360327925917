<template>
  <div id="home2" class="h-100">
    <img class="floor1" src="@/assets/images/patoma.png"/>
    <img class="floor2" src="@/assets/images/patoma.png"/>
    <img class="floor3" src="@/assets/images/patoma.png"/>
    <div class="home-container">
      <div class="circles" v-html="svgIcons.circles"/>

      <div class="patoma1">
        <div class="texts-floor1">
          <h2>Welcome</h2>

          <h5 class="mt-4">Ready. Set. Get to know us...</h5>
        </div>


          <img class="gear-big animate__animated animate__slow animate__fadeInLeft" src="@/assets/images/gear-big.png"/>
          <img class="gear-small animate__animated animate__fadeInLeft" src="@/assets/images/gear-small.png"/>
          <img class="developer " src="@/assets/images/developer.png" />
          <img class="reception" src="@/assets/images/reception.png"/>
          <img class="card-item animate__animated animate__slow animate__fadeInBottomRight" src="@/assets/images/card.png"/>

      </div>
      <div class="patoma2">

        <img class="restaurant" src="@/assets/images/restaurant.png" width="350"/>
        <img class="office" src="@/assets/images/office.png" width="300"/>
        <img class="mobile animate__fadeInRight animate__animated" src="@/assets/images/phone.png" width="50"/>
        <img class="main-character" src="@/assets/images/main-character.png"/>
<!--        <img class="floor" src="@/assets/images/patoma.png"/>-->

      </div>
      <div class="patoma3">

        <img class="chart1 animate__animated animate__fadeInTopLeft" src="@/assets/images/statistic2.png"/>
        <img class="chart2 animate__animated animate__fadeInLeft" src="@/assets/images/statistic.png"/>
        <img class="presentation" src="@/assets/images/meet-present.png"/>
        <img class="stoxos animate__animated animate_fast animate__fadeInBottomLeft" src="@/assets/images/stoxos.png" width="100"/>

        <div class="animated-arrow-down">
          <span></span>
          <span></span>
          <span></span>

        </div>

<!--        <img src="@/assets/images/arrows-down.png" class="animated-arrow-down" />-->
        <next-section-btn text="Όμιλος Motoroil" to-url="motoroil-group" class="mt-5 next-home"></next-section-btn>
        <img class="doctor" src="@/assets/images/doctor1.png"/>
        <img class="medicine animate__fadeInRight animate__animated" src="@/assets/images/medicine.png"/>
        <img class="pill animate__animated animate__slow animate__fadeInBottomRight" src="@/assets/images/pills.png"/>
<!--        <img class="floor" src="@/assets/images/patoma.png"/>-->

      </div>
      <div class="patoma4">
        <img class="cargoboat" src="@/assets/images/boat.png"/>
        <img class="diilistirio" src="@/assets/images/diilistirio.png"/>

        <img class="ktirio" src="@/assets/images/ktirio.png"/>

      </div>




    </div>

    <img class="sea" src="@/assets/images/sea.png" width="100%"/>
  </div>

</template>
<style lang="scss">
#home2{

  background: linear-gradient(89.82deg, #8D94CA 0.15%, #4497C8 99.84%);
  mix-blend-mode: normal;
  overflow:hidden;
  margin-bottom:0;
  padding-bottom:0;
  position:relative;

  .floor1,.floor2,.floor3{
    position:absolute;
    left:0;
    right:0;
    width:100%;
    height:15px;
  }
  .floor1{

    top:$floor-height - 15;
  }
  .floor2{
    top:$floor-height * 2 - 15;
  }
  .floor3{
    top:$floor-height * 3 - 15;
  }

  .circles{

    svg{
      bottom:130px;
    }

  }
  .home-container{
    max-width:1440px;
    margin:0 auto;
  }
  .floor{
    position:absolute;
    bottom:0;
    width:100%;
    left:0;
    right:0;
  }

  .patoma1,.patoma2,.patoma3,.patoma4{
    position:relative;
    height:$floor-height;
    img{
      z-index:1;
      position:absolute;
    }
  }
  .patoma1{
    .texts-floor1{
      position:absolute;
      top:20%;
      width:100%;
      text-align: center;
      color:#002D5D;
    }
    .developer{

      bottom:0;
      width:275px;
      left:15%;
    }
    .gear-big{
      top:20%;
      left:5%;
      width:60px;
    }
    .gear-small{
      top:45%;
      left:10%;
      width:30px;
    }

    .reception{
      bottom:0;
      width:300px;
      right:15%;
    }
    .card-item{
      right:10%;
      top:20%;
      width:50px;

    }

  }
  .patoma2{

    .restaurant{

      bottom:25%;
      width:375px;
      left:5%;
    }
    .main-character{
      height:390px;
      bottom:0;
      left:39%;
    }
    .office{
      bottom:-3%;
      width:350px;
      right:10%;
    }
    .mobile{
        right:3%;
        top:-5%;
    }
  }
  .patoma3{
    .animated-arrow-down{
      position:absolute;
      height:100px;
      left:50%;
      top:40%;
    }
    .next-home{
      position:absolute;
      bottom:-6%;
      left:43%;
    }
    .chart1{
      width:50px;
      left:5%;
      top:-5%;
    }
    .chart2{
      width:40px;
      left:12%;
      top:40%;
    }
    .presentation{
      bottom:0;
      left:20%;
      height:265px;
    }
    .stoxos{
      width:80px;
      bottom:-3%;
      left:6%;
    }

    .doctor{
      height:270px;
      bottom:0;
      right:20%;

    }
    .pill{
      bottom:25%;
      right:8%;
      width:45px;
    }
    .medicine{
      top:-6%;
      right:14%;
      width:70px;
    }

  }
  .patoma4{

    .cargoboat{
      bottom:-53px;
      width:400px;
      left:0;
    }
    .diilistirio{
      width:350px;
      right:3%;
      bottom:0;
    }
    .ktirio{
      width:275px;
      left:41%;
      bottom:0;
    }

  }
  .sea{
    z-index:2;
    position:relative;
    bottom: 0;
    left:0;
    right:0;
    height:100px;
  }
}
</style>
<script>
import NextSectionBtn from "../components/NextSectionBtn";
import svgIcons from "../components/svgIcons";

export default{

  components: {NextSectionBtn},
  computed:{
  },

  data(){
    return{
      svgIcons,
      currIndex: 0
    }
  },

  mounted(){
    this.toggleFloatAnimations();
  },

  methods:{
  }
}


</script>